<template>
<div class="flex justify-center p-4">
    <div>
      <h4 class="font-bold text-lg">Amministrazione</h4>
      <p class="mb-4">Da qui puoi gestire l'intero applicativo</p>
      <router-link class="bg-yellow-400 mb-1 p-2 w-full block" to="/queue">Visualizza la coda richieste membership</router-link>
      <router-link class="bg-yellow-400 mb-1 p-2 w-full block" to="/branch/queue">Visualizza la coda richieste branch</router-link>

      <router-link class="bg-orange-400 mb-1 p-2 w-full block" to="/all">Esplora tutti gli account</router-link>

      <router-link class="bg-lime-400 mb-1 p-2 w-full block" to="/member">Aggiungi manualmente</router-link>
      <router-link @click="exit" class="bg-red-500 mb-1 p-2 w-full block" to="/"> Disconnetti profilo</router-link>
    </div>
</div>
</template>

<script>
import { RouterLink } from 'vue-router'
export default {
  name: 'AdminView',
  components: {RouterLink},
  methods: {
    exit(){
      sessionStorage.clear();
    }
  }
}
</script>