<template>
  <div class="w-full p-4">
    <MemberAllComponent />
  </div>
</template>

<script>
import MemberAllComponent from './components/MemberAllComponent.vue';

export default {
  name: 'BecomeMember',
  components: { MemberAllComponent }
}
</script>