<template>
  <div class="w-full p-4">
    
    <MemberQueueComponent />
  </div>
</template>

<script>
import MemberQueueComponent from './components/MemberQueueComponent.vue';

export default {
  name: 'BecomeMember',
  components: { MemberQueueComponent }
}
</script>