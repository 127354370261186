<template>
  <HeaderNavigation />
  <!-- LOGIN PAGE
			============================================= -->
  <div id="login" class="bg--fixed login-1 login-section division">
    <div class="container inner-page-hero">

      <!-- SECTION TITLE -->
      <div class="row justify-content-center">
        <div class="col-md-10 col-lg-9">
          <div class="section-title text-center mb-80">

            <!-- Title -->
            <h2 class="s-52 w-700">Login</h2>

            <!-- Text -->
            <p class="p-lg">
              Access your account to update your company profile, and check other members details. Enter your credentials to get started.
            </p>

          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-8 col-lg-6 offset-md-2 offset-lg-3">
          <div class="register-page-form">




            <LogInComponent />



          </div>
        </div>
      </div> <!-- End row -->
    </div> <!-- End container -->

  </div> <!-- END LOGIN PAGE -->
  <FooterComponent />


</template>

<script>
import HeaderNavigation from './components/HeaderNavigation.vue';
import LogInComponent from './components/LogInComponent.vue';
import FooterComponent from './components/FooterComponent.vue';
export default {
  name: 'BecomeMember',
  components: { LogInComponent, HeaderNavigation, FooterComponent }
}
</script>