<template>
    <button class="bg-yellow-400 px-4 py-2 rounded-xl font-bold mb-4" @click="router.go(-1)">Torna indietro</button>
    <div class="w-full p-4">
        <div class="border mb-6 block border-gray-400 bg-gray-50 p-4 rounded-xl shadow-xl">
            <h2 class="text-center text-lg font-black mb-4">Branch Add</h2>

            <div class="flex items-center justify-between mb-2" v-if="form.membership_id">
                <label class="w-4/12 font-bold text-right">Membership ID</label>
                <div class="w-6/12">
                    <input disabled autocomplete="branch-name" v-model="form.membership_id" class="w-full border border-gray-400 p-2 rounded" type="text" placeholder="Insert branch name">
                </div>
            </div>

            <div class="flex items-center justify-between mb-2" v-if="status != ''">
                <label class="w-4/12 font-bold text-right">Status</label>
                <div class="w-6/12">
                    <input disabled autocomplete="branch-name" class="w-full border border-gray-400 p-2 rounded" type="text" :value="this.status">
                </div>
            </div>

            <div class="flex items-center justify-between mb-2">
                <label class="w-4/12 font-bold text-right">Branch Name</label>
                <div class="w-6/12">
                    <input autocomplete="branch-name" v-model="form.branchName" class="w-full border border-gray-400 p-2 rounded" type="text" placeholder="Insert branch name">
                </div>
            </div>

            <div class="flex items-center justify-between mb-2">
                <label class="w-4/12 font-bold text-right">Address</label>
                <input autocomplete="address" v-model="form.address" class="w-6/12 border border-gray-400 p-2 rounded" type="text" placeholder="Insert address">
            </div>

            <div class="flex items-center justify-between mb-2">
                <label class="w-4/12 font-bold text-right">Country</label>
                <input autocomplete="country" v-model="form.country" class="w-6/12 border border-gray-400 p-2 rounded" type="text" placeholder="Insert country">
            </div>

            <div class="flex items-center justify-between mb-2">
                <label class="w-4/12 font-bold text-right">City</label>
                <input autocomplete="city" v-model="form.city" class="w-6/12 border border-gray-400 p-2 rounded" type="text" placeholder="Insert city">
            </div>

            <div class="flex items-center justify-between mb-2">
                <label class="w-4/12 font-bold text-right">Email</label>
                <input autocomplete="email" v-model="form.email" class="w-6/12 border border-gray-400 p-2 rounded" type="text" placeholder="Insert email address">
            </div>

            <div class="flex items-center justify-between mb-2">
                <label class="w-4/12 font-bold text-right">Phone</label>
                <input autocomplete="tel" v-model="form.phone" class="w-6/12 border border-gray-400 p-2 rounded" type="text" placeholder="Insert phone number">
            </div>

            <div class="flex items-center justify-between mb-2">
                <label class="w-4/12 font-bold text-right">Mobile</label>
                <input autocomplete="mobile" v-model="form.mobile" class="w-6/12 border border-gray-400 p-2 rounded" type="text" placeholder="Insert mobile number">
            </div>

            <div class="flex items-center justify-between mb-2">
                <label class="w-4/12 font-bold text-right">IATA Code</label>
                <input autocomplete="iata-code" v-model="form.iataCode" class="w-6/12 border border-gray-400 p-2 rounded" type="text" placeholder="Insert IATA code">
            </div>

            <div class="flex items-center justify-between mb-2">
                <label class="w-4/12 font-bold text-right">Website</label>
                <input autocomplete="website" v-model="form.website" class="w-6/12 border border-gray-400 p-2 rounded" type="text" placeholder="Insert website URL">
            </div>

            <div class="bg-gray-400">
                <h4>Contact</h4>

                <button @click="addContact" class="mb-2">Add contact</button>
                <button @click="removeContact(index)">Remove contact</button>
                <div v-for="(contact, index) in contacts" :key="index">
                    <div class="flex items-center justify-between mb-2">
                        <label class="w-4/12 font-bold text-right">Name</label>
                        <input v-model="contact.name" class="w-6/12 border border-gray-400 p-2 rounded" type="text" placeholder="Insert name">
                    </div>
                    <div class="flex items-center justify-between mb-2">
                        <label class="w-4/12 font-bold text-right">title</label>
                        <input v-model="contact.title" class="w-6/12 border border-gray-400 p-2 rounded" type="text" placeholder="Insert title">
                    </div>
                    <div class="flex items-center justify-between mb-2">
                        <label class="w-4/12 font-bold text-right">position</label>
                        <input v-model="contact.position" class="w-6/12 border border-gray-400 p-2 rounded" type="text" placeholder="Insert position">
                    </div>
                    <div class="flex items-center justify-between mb-2">
                        <label class="w-4/12 font-bold text-right">phone</label>
                        <input v-model="contact.phone" class="w-6/12 border border-gray-400 p-2 rounded" type="text" placeholder="Insert phone">
                    </div>
                    <div class="flex items-center justify-between mb-2">
                        <label class="w-4/12 font-bold text-right">mobile</label>
                        <input v-model="contact.mobile" class="w-6/12 border border-gray-400 p-2 rounded" type="text" placeholder="Insert mobile">
                    </div>
                    <div class="flex items-center justify-between mb-2">
                        <label class="w-4/12 font-bold text-right">email</label>
                        <input v-model="contact.email" class="w-6/12 border border-gray-400 p-2 rounded" type="text" placeholder="Insert email">
                    </div>
                    <div class="flex items-center justify-between mb-2">
                        <label class="w-4/12 font-bold text-right">skype</label>
                        <input v-model="contact.skype" class="w-6/12 border border-gray-400 p-2 rounded" type="text" placeholder="Insert skype">
                    </div>
                    <div class="flex items-center justify-between mb-2">
                        <label class="w-4/12 font-bold text-right">main</label>
                        <input v-model="contact.main" class="w-6/12 border border-gray-400 p-2 rounded" type="text" placeholder="Insert main">
                    </div>
                </div>


            </div>


            <div class="flex items-center" v-if="this.privilege && status == 'PENDING'">
                <button @click="manage('APPROVE')" class="bg-lime-400 mr-2 p-4 font-bold rounded-xl mt-4">Approva richiesta</button>
                <button @click="manage('REMOVE')" class="bg-red-500 mr-2 p-4 font-bold rounded-xl mt-4">Rimuovi richiesta</button>
                <button class="bg-yellow-400 p-4 font-bold rounded-xl mt-4">Contatta l'utente</button>
            </div>

            <button v-else @click="send_subscription" class="w-full bg-lime-400 p-4 font-bold rounded-xl mt-4">Submit your request</button>


        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { useRouter } from 'vue-router';
export default {
    name: 'BranchAdd',
    data() {
        return {
            contacts: [
                {
                    name: '',
                    title: '',
                    position: '',
                    phone: '',
                    mobile: '',
                    email: '',
                    skype: '',
                    main: false
                }
            ],
            form: {
                branchName: '',
                address: '',
                country: '',
                city: '',
                email: '',
                phone: '',
                mobile: '',
                iataCode: '',
                website: ''
            },
            membership:{},
            status: "",
            privilege: false
        }
    },
    mounted(){
        if(this.$route.params.membership_id){
            this.check_queue(this.$route.params.membership_id)
        }
        const home = sessionStorage.getItem("home");
        if(home == '/admin'){
            this.privilege = true;
        }
    },
    setup() {
        const router = useRouter();
        return {
            router
        };
    },
    methods: {
        async check_queue(membership_id) {
            const request = await axios({ method: 'GET', url: 'https://unftl-uafatu7bdq-nw.a.run.app/branch/details?membership_id=' + membership_id });
            if (request) {
                this.form = request['data']['form'];
                this.contacts = request['data']['contact'];
                this.status = request['data']['status']
            }
        },
        addContact() {
            this.contacts.push({
                name: '',
                title: '',
                position: '',
                phone: '',
                mobile: '',
                email: '',
                skype: '',
                main: false
            });
        },
        removeContact(index) {
            this.contacts.splice(index, 1);
        },
        async send_subscription() {
            const request = await axios({
                method: 'POST',
                url: 'https://unftl-uafatu7bdq-nw.a.run.app/branch/add',
                data: {
                    form: this.form,
                    contact: this.contacts,
                    email: sessionStorage.getItem("email")
                }
            });
            if (request) {
                if (request['data']['message']) {
                    alert(request['data']['message']);
                }
            }
        },
        async manage(action) {
            const request = await axios({ 
                method: 'POST', 
                url: 'https://unftl-uafatu7bdq-nw.a.run.app/branch/manage',
                data: {
                    "membership_id": this.$route.params.membership_id,
                    "action": action
                }
            });
            if(request){
                if(request['data']['message']){
                alert(request['data']['message']);
                }
            }
        }
    }
}
</script>