<template>
  <HeaderNavigation />






  <!-- FEATURES-12
			============================================= -->
  <section id="features-12" class="shape--bg shape--white-500 features-section division">
    <div class="container inner-page-hero">

      <!-- SECTION TITLE -->
      <div class="row justify-content-center">
        <div class="col-md-10 col-lg-9">
          <div class="section-title text-center mb-80">
            <!-- Title -->
            <img src="images/intermodal_logo.png" style="width: 426px;margin: auto;display: block;">
            <p style="margin-top:30px;"><strong style="font-size:24px;">FROM APRIL 22ND TO 24TH 2025</strong><br>Pavilhão de Exposições do Distrito Anhembi| <i>São Paulo</i></p>
          </div>
        </div>
      </div>


      <!-- FEATURES-7
			============================================= -->
      <section id="features-7" class="features-section division">
        <div class="container">


          <!-- FEATURES-7 WRAPPER -->
          <div class="fbox-wrapper text-center">
            <div class="row row-cols-1 row-cols-md-2 row-cols-lg-4">

              <!-- FEATURE BOX #3 Promotion -->
              <div class="col">
                <div class="fbox-7 fb-3 r-12 wow fadeInUp">

                  <!-- Icon -->
                  <div class="fbox-ico ico-50">
                    <div class="shape-ico color--theme">

                      <!-- Vector Icon -->
                      <span class="flaticon-rocket-launch"></span>

                      <!-- Shape -->
                      <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                        <path d="M69.8,-23C76.3,-2.7,57.6,25.4,32.9,42.8C8.1,60.3,-22.7,67,-39.1,54.8C-55.5,42.7,-57.5,11.7,-48.6,-11.9C-39.7,-35.5,-19.8,-51.7,5.9,-53.6C31.7,-55.6,63.3,-43.2,69.8,-23Z" transform="translate(100 100)" />
                      </svg>

                    </div>
                  </div> <!-- End Icon -->

                  <!-- Text -->
                  <div class="fbox-txt">
                    <h6 class="s-20 w-700">Make your time more profitable</h6>
                    <p>Avoid long, random meetings by having a <strong style="color:#fdf100">fully pre-planned</strong> schedule to maximize your efficiency.</p>
                  </div>

                </div>
              </div> <!-- END FEATURE BOX #3 -->

              <!-- FEATURE BOX #1 Conferences -->
              <div class="col">
                <div class="fbox-7 fb-1 r-12 wow fadeInUp">

                  <!-- Icon -->
                  <div class="fbox-ico ico-50">
                    <div class="shape-ico color--theme">

                      <!-- Vector Icon -->
                      <span class="flaticon-idea"></span>

                      <!-- Shape -->
                      <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                        <path d="M69.8,-23C76.3,-2.7,57.6,25.4,32.9,42.8C8.1,60.3,-22.7,67,-39.1,54.8C-55.5,42.7,-57.5,11.7,-48.6,-11.9C-39.7,-35.5,-19.8,-51.7,5.9,-53.6C31.7,-55.6,63.3,-43.2,69.8,-23Z" transform="translate(100 100)" />
                      </svg>

                    </div>
                  </div> <!-- End Icon -->

                  <!-- Text -->
                  <div class="fbox-txt">
                    <h6 class="s-20 w-700">Opportunity to save your money</h6>
                    <p><strong style="color:#fdf100">Reduce expenses</strong> by sharing costs with other co-exhibitors, making participation more affordable.</p>
                  </div>

                </div>
              </div> <!-- END FEATURE BOX #1 -->

              <!-- FEATURE BOX #1Prevention -->
              <div class="col">
                <div class="fbox-7 fb-1 r-12 wow fadeInUp">

                  <!-- Icon -->
                  <div class="fbox-ico ico-50">
                    <div class="shape-ico color--theme">

                      <!-- Vector Icon -->
                      <span class="flaticon-graphic"></span>

                      <!-- Shape -->
                      <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                        <path d="M69.8,-23C76.3,-2.7,57.6,25.4,32.9,42.8C8.1,60.3,-22.7,67,-39.1,54.8C-55.5,42.7,-57.5,11.7,-48.6,-11.9C-39.7,-35.5,-19.8,-51.7,5.9,-53.6C31.7,-55.6,63.3,-43.2,69.8,-23Z" transform="translate(100 100)" />
                      </svg>

                    </div>
                  </div> <!-- End Icon -->

                  <!-- Text -->
                  <div class="fbox-txt">
                    <h6 class="s-20 w-700">Show & promote your company</h6>
                    <p>Invest a low budget to showcase and <strong style="color:#fdf100">promote your company</strong> among thousands of attendees.</p>
                  </div>

                </div>
              </div> <!-- END FEATURE BOX #1 -->

              <!-- FEATURE BOX #3 Trade Lanes -->
              <div class="col">
                <div class="fbox-7 fb-3 r-12 wow fadeInUp">

                  <!-- Icon -->
                  <div class="fbox-ico ico-50">
                    <div class="shape-ico color--theme">

                      <!-- Vector Icon -->
                      <span class="flaticon-prioritize"></span>

                      <!-- Shape -->
                      <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                        <path d="M69.8,-23C76.3,-2.7,57.6,25.4,32.9,42.8C8.1,60.3,-22.7,67,-39.1,54.8C-55.5,42.7,-57.5,11.7,-48.6,-11.9C-39.7,-35.5,-19.8,-51.7,5.9,-53.6C31.7,-55.6,63.3,-43.2,69.8,-23Z" transform="translate(100 100)" />
                      </svg>

                    </div>
                  </div> <!-- End Icon -->

                  <!-- Text -->
                  <div class="fbox-txt">
                    <h6 class="s-20 w-700">Chance to get more<br> ideas</h6>
                    <p>Learn from others on <strong style="color:#fdf100">how to develop your business</strong> in your country by sharing experiences.</p>
                  </div>

                </div>
              </div> <!-- END FEATURE BOX #3 -->


            </div> <!-- End row -->
          </div> <!-- END FEATURES-7 WRAPPER -->


        </div> <!-- End container -->
      </section> <!-- END FEATURES-7 -->

    </div> <!-- End container -->
  </section> <!-- END FEATURES-12 -->

  <!-- ABOUT-2 IMAGES -->
  <div class="container-fluid pt-50 mt-50">
    <div class="row">


      <!-- IMAGES-1 -->
      <div class="col-md-5">
        <div class="text-end">


          <!-- IMAGE-1 -->
          <div class="about-2-img a-2-1 r-12">
            <img class="img-fluid" src="mosaico/1.jpg" alt="about-image">
          </div>


          <!-- IMAGE-2 -->
          <div class="about-2-img a-2-2 r-12">
            <img class="img-fluid" src="mosaico/2.jpg" alt="about-image">
          </div>


        </div>
      </div> <!-- END IMAGES-1 -->


      <!-- IMAGES-2 -->
      <div class="col-md-7">


        <!-- IMAGE-3 -->
        <div class="about-2-img a-2-3 r-12">
          <img class="img-fluid" src="mosaico/3.jpg" alt="about-image">
        </div>


        <div class="row">


          <!-- IMAGE-4 -->
          <div class="col-md-5 col-lg-6">
            <div class="about-2-img a-2-4 r-12">
              <img class="img-fluid" src="mosaico/4.jpg" alt="about-image">
            </div>
          </div>

        </div> <!-- End row -->


      </div> <!-- END IMAGES-2 -->


    </div> <!-- End row -->
  </div> <!-- END ABOUT-2 IMAGES -->

  <section id="features-7" class="pt-100 features-section division">
    <div class="container">


      <div class="row justify-content-center">
        <div class="col-md-10 col-lg-9">
          <div class="section-title mb-70"><!-- Title -->
            <h2 class="s-50 w-700">DISCOVER WHAT <strong style="color:#fdf100;">WE OFFER</strong></h2><!-- Text -->
          </div>
        </div>
      </div>

      <!-- SECTION TITLE -->
      <div class="row justify-content-center list_advanced">

        <ul>
          <li><strong>Individual station</strong> fully equipped with comfortable larger space, power socket, 4 chairs, and a large customized company banner.</li>
          <li><strong>Online scheduler app</strong> available for pre-scheduling meetings.</li>
          <li><strong>Six months of dedicated promotion</strong> via social media.</li>
          <li><strong>Targeted mailing</strong> to over 165,000 contacts from our database.</li>
          <li><strong>Hospitality area</strong> for additional casual or random meetings.</li>
          <li><strong>Full-day bar/catering service</strong> for residents.</li>
          <li><strong>Experienced and well-trained receptionists</strong> to assist co-exhibitors.</li>
          <li><strong>Transportation</strong> between the hotel/exhibition venue and vice versa.</li>
          <li><strong>Exclusive gala dinner</strong> at Sao Paulo's finest restaurant, with one invitation per company.</li>
          <li><strong>Customized and unforgettable musical show party.</strong></li>
          <li><strong>Custom-designed polo shirt</strong> with exclusive company logo for exhibition wear.</li>
          <li><strong>Photo/video service</strong> provided.</li>
          <li><strong>Access badge</strong> labeled as "Exhibitor".</li>
          <li><strong>Company logo</strong> prominently displayed on a large wall within the booth.</li>
          <li><strong>Company logo and profile</strong> featured on the official event website/platform.</li>
          <li><strong>Additional details</strong> to be disclosed on site.</li>
        </ul>

        <img src="images/even_dmo.png">

      </div>
    </div>
  </section>


  <!-- CONTACTS-1
			============================================= -->
  <section id="contacts-1" class="pt-50 contacts-section division">
    <div class="container">

      <div class="row justify-content-center">
        <div class="col-md-10 col-lg-9">
          <div class="section-title text-center mb-80">

            <!-- Title -->
            <h2 class="s-52 w-700">Book It Now</h2>
          </div>
        </div>
      </div>

      <!-- CONTACT FORM -->
      <div class="row justify-content-center">
        <div class="col-md-11 col-lg-10 col-xl-8">
          <div class="form-holder">
            <form name="contactform" class="row contact-form">

              <!-- Contact Form Input -->
              <div class="col-md-12">
                <p class="p-lg">Name: </p>
                <input v-model="form.name" type="text" name="name" class="form-control name" placeholder="Your Name*">
              </div>

              <div class="col-md-12">
                <p class="p-lg">Surname: </p>
                <input v-model="form.surname" type="text" name="surname" class="form-control surname" placeholder="Your Surname*">
              </div>

              <div class="col-md-12">
                <p class="p-lg">Company: </p>
                <input v-model="form.company" type="text" name="company" class="form-control company" placeholder="Company Name*">
              </div>

              <div class="col-md-12">
                <p class="p-lg">Position: </p>
                <input v-model="form.position" type="text" name="position" class="form-control position" placeholder="Job Position*">
              </div>

              <div class="col-md-12">
                <p class="p-lg">Email: </p>
                <input v-model="form.email" type="email" name="email" class="form-control email" placeholder="Email Address*">
              </div>

              <div class="col-md-12">
                <p class="p-lg">Mobile: </p>
                <input v-model="form.mobile" type="tel" name="mobile" class="form-control mobile" placeholder="Mobile Number*">
              </div>

              <div class="col-md-12">
                <p class="p-lg">Country: </p>
                <input v-model="form.country" type="text" name="country" class="form-control country" placeholder="Country*">
              </div>
            </form>
            <!-- Contact Form Button -->
            <div class="col-md-12 mt-15 form-btn text-right">
              <button @click="send" type="submit" class="btn btn--theme hover--theme submit">Submit Request</button>
            </div>

            <!-- Contact Form Message -->
            <div class="col-lg-12 contact-form-msg">
              <span class="loading"></span>
            </div>



          </div>
        </div>
      </div> <!-- END CONTACT FORM -->


    </div> <!-- End container -->
  </section> <!-- END CONTACTS-1 -->

  <FooterComponent />


</template>

<script>
import HeaderNavigation from './components/HeaderNavigation.vue';
import FooterComponent from './components/FooterComponent.vue';
import { createToast } from 'mosha-vue-toastify';
import axios from 'axios';
import 'mosha-vue-toastify/dist/style.css'
export default {
  name: 'EventView',
  components: { HeaderNavigation, FooterComponent },
  data() {
    return {
      form: {
        email: ''
      },
      response: {}
    }
  },
  methods: {
    async send() {
      console.log(this.form);
      if (this.form.email == '') {
        createToast({
          title: 'Book Now',
          description: "Fill in all the fields of the form before sending the request"
        },
          {
            showIcon: 'true',
            position: 'top-center',
            type: 'danger',
          })
        return true;
      }
      const response = await axios({
        method: 'POST',
        url: 'https://unftl-uafatu7bdq-nw.a.run.app/event/book',
        data: this.form
      });
      if (response) {
        this.response = response['data'];
        createToast({
          title: 'Book Now',
          description: "Your data has been entered successfully, you will receive a summary email"
        },
          {
            showIcon: 'true',
            position: 'top-center',
          })
      }
    }
  }
}
</script>