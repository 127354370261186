<template>


  <HeaderNavigation />

  <!-- BLOG POSTS LISTING
			============================================= -->
  <section id="blog-page" class="pb-60 inner-page-hero blog-page-section" style="padding-top:130px;">



    <div class="container">

      <!--  SINGLE POST TITLE -->
      <div class="single-post-title text-center" style="margin-bottom:80px;">
        <!-- Title -->
        <h2 class="s-46 w-700">Search Engine</h2>
      </div>


      <div class="search_engine">
        <input v-model="form.city" ref="autocompleteInput" type="text" class="border w-full border-gray-400 p-2" placeholder="City..." @input="handleInput" />
        <div class="adv_search" style="display:none">

          <div class="sub">
            <label class="text-left font-bold">Member ID</label>
            <input class="border border-gray-400 w-full p-2" type="text" v-model="form.search" placeholder="Search...">
          </div>

          <p>Advanced Search</p>
          <input type="checkbox">FCL <span style="margin-right:5px;"></span>
          <input type="checkbox">LCL <span style="margin-right:5px;"></span>
          <input type="checkbox">FTL <span style="margin-right:5px;"></span>
          <input type="checkbox">LTL <span style="margin-right:5px;"></span>
          <input type="checkbox">AIR <span style="margin-right:5px;"></span>
          <input type="checkbox">WAREHOUSING <span style="margin-right:5px;"></span>
          <input type="checkbox">CUSTOM CLEARANCE
        </div>

        <div class="clear"></div>

      </div>




      <!-- POSTS WRAPPER -->
      <div class="posts-wrapper">
        <div class="row">

          <!-- BLOG POST #1 -->
          <div @click="member_details(member?.form?.membership_id)" class="col-md-6 col-lg-4" v-for="member, idx in this.member" v-bind:key="idx">
            <div class="blog-post mb-40 wow fadeInUp clearfix boxbox">


              <!-- BLOG POST IMAGE -->
              <div class="blog-post-img mb-35">
                <img style="background:#FFF;" class="img-fluid r-16" src="https://upload.wikimedia.org/wikipedia/commons/3/3c/Wl-logo-horizontal.png" alt="blog-post-image">
              </div>


              <!-- BLOG POST TEXT -->
              <div class="blog-post-txt">

                <!-- Post Tag -->
                <span class="post-tag color--red-400" style="margin-bottom:0px;">Membership ID</span>
                <span>{{ member?.form?.membership_id }}</span>

                <!-- Post Link -->
                <h6 class="s-20 w-700" style="margin-top:20px;">
                  <a href="#">{{ member?.form?.branchName }}</a>
                </h6>

                <!-- Text -->
                <p>{{ member?.form?.country }}
                </p>

                <!-- Post Meta -->
                <div class="blog-post-meta mt-20">
                  <ul class="post-meta-list ico-10">
                    <li>
                      <p class="p-sm w-500">{{ member?.form?.city }}</p>
                    </li>
                  </ul>
                </div>

              </div> <!-- END BLOG POST TEXT -->


            </div>
          </div> <!-- END BLOG POST #1 -->



        </div>
      </div> <!-- END POSTS WRAPPER -->





    </div> <!-- End container -->
  </section> <!-- END BLOG POSTS LISTING -->






  <!-- PAGE PAGINATION
			============================================= -->
  <div class="pb-100 page-pagination theme-pagination">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <nav aria-label="Page navigation">
            <ul class="pagination ico-20 justify-content-center">
              <li class="page-item disabled"><a class="page-link" href="#" tabindex="-1"><span class="flaticon-back"></span></a>
              </li>
              <li class="page-item active" aria-current="page"><a class="page-link" href="#">1</a></li>
              <li class="page-item"><a class="page-link" href="#">2</a></li>
              <li class="page-item"><a class="page-link" href="#">3</a></li>
              <li class="page-item"><a class="page-link" href="#" aria-label="Next"><span class="flaticon-next"></span></a></li>
            </ul>
          </nav>
        </div>
      </div> <!-- End row -->
    </div> <!-- End container -->
  </div> <!-- END PAGE PAGINATION -->

  <router-link class="bg-orange-500 mb-1 p-2 w-full block" to="/profile">Edit your profile</router-link>
  <router-link class="bg-lime-500 mb-1 p-2 w-full block" to="/branch/view">Branches</router-link>
  <router-link @click="exit" class="bg-red-500 mb-1 p-2 w-full block" to="/">Disconnetti profilo</router-link>

</template>

<script>
import HeaderNavigation from './components/HeaderNavigation.vue';
import { RouterLink } from 'vue-router';
import { useRouter } from 'vue-router';
import { ref, onMounted } from 'vue';
import { Loader } from '@googlemaps/js-api-loader';
import axios from 'axios';
export default {
  name: 'SearchView',
  components: { RouterLink, HeaderNavigation },
  setup() {

    const autocompleteInput = ref(null);
    let autocomplete;

    const handleInput = () => {
      if (autocomplete) {
        console.log('Città selezionata:', autocompleteInput.value.value);
        // Qui puoi aggiungere la logica per gestire la città selezionata
      }
    };

    onMounted(async () => {
      const loader = new Loader({
        apiKey: 'AIzaSyBOMJreHKC8wyxy8UQo8uPLS6tVcXLbiCU',
        version: 'weekly',
        libraries: ['places']
      });

      const google = await loader.load();

      autocomplete = new google.maps.places.Autocomplete(autocompleteInput.value, {
        types: ['(cities)']
      });

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        if (!place.geometry) {
          console.log("Nessun dettaglio disponibile per l'input: '" + place.name + "'");
          return;
        }

        console.log('Città selezionata:', place.name);
        console.log('Latitudine:', place.geometry.location.lat());
        console.log('Longitudine:', place.geometry.location.lng());
        // Qui puoi aggiungere la logica per gestire la città selezionata
      });
    });
    const router = useRouter();
    return {
      autocompleteInput,
      handleInput,
      router
    };
  },
  data() {
    return {
      form: {
        search: "",
        network: "REGULAR",
        country: "",
        city: "",
        status: "ACTIVE",
        pplcan: "NO"
      },
      member: {}
    }
  },
  methods: {
    exit() {
      sessionStorage.clear();
    },
    async engine() {
      const request = await axios({ method: 'POST', url: 'https://unftl-uafatu7bdq-nw.a.run.app/search/nation', data: this.form });
      if (request) {
        this.member = request['data'];
      }
    },
    member_details(id) {
      this.router.push("/branch/details/" + id);
    }
  }
}
</script>