<template>
  <HeaderNavigation />

  <!-- CONTACTS-1
			============================================= -->
  <section id="contacts-1" class="pb-50 inner-page-hero contacts-section division">
    <div class="container">


      <!-- SECTION TITLE -->
      <div class="row justify-content-center">
        <div class="col-md-10 col-lg-9">
          <div class="section-title text-center mb-80">

            <!-- Title -->
            <h2 class="s-52 w-700">Questions? Let's Talk</h2>
          </div>
        </div>
      </div>


      <!-- CONTACT FORM -->
      <div class="row justify-content-center">
        <div class="col-md-11 col-lg-10 col-xl-8">
          <div class="form-holder">
            <form name="contactform" class="row contact-form">

              <!-- Contact Form Input -->
              <div class="col-md-12">
                <p class="p-lg">Your Name: </p>
                <input type="text" name="name" class="form-control name" placeholder="Your Name*">
              </div>

              <div class="col-md-12">
                <p class="p-lg">Your Email Address: </p>
                <input type="text" name="email" class="form-control email" placeholder="Email Address*">
              </div>

              <div class="col-md-12">
                <p class="p-lg">Your Country: </p>
                <input type="text" name="country" class="form-control country" placeholder="Your country*">
              </div>

              <div class="col-md-12">
                <p class="p-lg">Message: </p>
                <textarea class="form-control message" name="message" rows="6" placeholder="I have a problem with..."></textarea>
              </div>

              <!-- Contact Form Button -->
              <div class="col-md-12 mt-15 form-btn text-right">
                <button type="submit" class="btn btn--theme hover--theme submit">Submit Request</button>
              </div>

              <div class="contact-form-notice">
                <p class="p-sm">We are committed to your privacy. UnitedNations uses the information you
                  provide us to contact you about our relevant content, products, and services.
                  You may unsubscribe from these communications at any time. For more information,
                  check out our <a href="privacy.html">Privacy Policy</a>.
                </p>
              </div>

              <!-- Contact Form Message -->
              <div class="col-lg-12 contact-form-msg">
                <span class="loading"></span>
              </div>

            </form>
          </div>
        </div>
      </div> <!-- END CONTACT FORM -->


    </div> <!-- End container -->
  </section> <!-- END CONTACTS-1 -->






  <FooterComponent />


</template>

<script>
import HeaderNavigation from './components/HeaderNavigation.vue';
import FooterComponent from './components/FooterComponent.vue';
export default {
  name: 'ContactView',
  components: { HeaderNavigation, FooterComponent }
}
</script>