<template>

    <HeaderNavigation />





    <!-- SIGN UP PAGE
    ============================================= -->
    <div id="signup" class="bg--fixed signup-1 login-section division">
        <div class="container inner-page-hero">

                              <!-- SECTION TITLE -->
          <div class="row justify-content-center">
            <div class="col-md-10 col-lg-9">
              <div class="section-title text-center mb-80">

                <!-- Title -->
                <h2 class="s-52 w-700">Become a member</h2>

                <!-- Text -->
                <p class="p-lg">Join our community to unlock exclusive benefits and features. Sign up now to start your journey with us and enjoy all the perks of membership.
                </p>

              </div>
            </div>
          </div>

            <div class="row">
                <div class="col-md-8 col-lg-6 offset-md-2 offset-lg-3">	
                    <div class="register-page-form">


                        

                        <BecomeMemberComponent />

                    </div>	
                </div>
            </div>	   <!-- End row -->	
        </div>	   <!-- End container -->		
    </div>	<!-- END SIGN UP PAGE -->


    <FooterComponent/>



    <div class="w-full p-4">
        
    </div>
</template>

<script>
import HeaderNavigation from './components/HeaderNavigation.vue';
import BecomeMemberComponent from './components/BecomeMemberComponent.vue';
import FooterComponent from './components/FooterComponent.vue';
export default {
    name: 'BecomeMember',
    components: { BecomeMemberComponent,HeaderNavigation,FooterComponent }
}
</script>