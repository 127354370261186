<template>
  <button class="bg-yellow-400 px-4 py-2 rounded-xl font-bold mb-4" @click="router.go(-1)">Torna indietro</button>
  <div v-if="details" class="border mb-6 block border-gray-400 bg-gray-50 p-4 rounded-xl shadow-xl">
    <h2 class="text-center text-lg font-black mb-4">Info member</h2>

    <div class="flex items-center justify-between mb-2 border-b border-gray-400">
      <label class="w-4/12 font-bold text-right">Name</label>
      <p class="w-7/12">{{ details.name }}</p>
    </div>
    <div class="flex items-center justify-between mb-2 border-b border-gray-400">
      <label class="w-4/12 font-bold text-right">Email Address</label>
      <p class="w-7/12">{{ details.email }}</p>
    </div>

    <div v-if="this.privilege" class="flex items-center justify-between mb-2 border-b border-gray-400">
      <label class="w-4/12 font-bold text-right">Password</label>
       <p class="w-7/12"> {{ details.password }}</p>
    </div>
    <div class="flex items-center justify-between mb-2 border-b border-gray-400">
      <label class="w-4/12 font-bold text-right">Country</label>
      <p class="w-7/12">{{details.country}}</p>
    </div>
    <div class="flex items-center justify-between mb-2 border-b border-gray-400">
      <label class="w-4/12 font-bold text-right">Phone Number</label>
      <p class="w-7/12">{{ details.phone }}</p>
    </div>
    <div class="flex items-center justify-between mb-2 border-b border-gray-400">
      <label class="w-4/12 font-bold text-right">Mobile Number</label>
      <p class="w-7/12">{{ details.mobile}}</p>
    </div>
    <div class="flex items-center justify-between mb-2 border-b border-gray-400">
      <label class="w-4/12 font-bold text-right">Web</label>
       <p class="w-7/12"> {{ details.site }}</p>
    </div>

    <div class="flex items-center justify-between mb-2">
      <label class="w-4/12 font-bold text-right">profile_notes</label>
       <p class="w-7/12"> {{ details.profile_notes }}</p>
    </div>

    <div class="flex items-center" v-if="this.privilege && !details.approved">
      <button @click="manage('_APPROVE')" class="bg-lime-400 mr-2 p-4 font-bold rounded-xl mt-4">Approva richiesta</button>
      <button @click="manage('_REMOVE')" class="bg-red-500 mr-2 p-4 font-bold rounded-xl mt-4">Rimuovi richiesta</button>
      <button class="bg-yellow-400 p-4 font-bold rounded-xl mt-4">Contatta l'utente</button>
    </div>
    <div class="flex items-center" v-else-if="this.privilege && details.approved">
      <button @click="manage('_REMOVE')" class="bg-red-500 mr-2 p-4 font-bold rounded-xl mt-4">Revoca accesso </button>
      <button class="bg-yellow-400 p-4 font-bold rounded-xl mt-4">Contatta l'utente</button>
    </div>
    <div v-else>
      <button class="bg-yellow-400 p-4 font-bold rounded-xl mt-4">Contatta l'utente</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { useRouter } from 'vue-router';
export default {
  name: 'MemberDetailsComponent',
  props: {
    email: String
  },
  setup() {
    const router = useRouter();
    return {
      router
    };
  },
  data() {
    return {
      details: {},
      privilege: false
    }
  },
  mounted() {
    const home = sessionStorage.getItem("home");
    if(home == '/admin'){
      this.privilege = true;
    }
    this.member_details(this.email);
  },
  methods: {
    async member_details(email) {
      const request = await axios({ method: 'GET', url: 'https://unftl-uafatu7bdq-nw.a.run.app/member/details?email='+email });
      if (request) {
        this.details = request['data'];
      }
    },
    async manage(action) {
      const request = await axios({ 
        method: 'POST', 
        url: 'https://unftl-uafatu7bdq-nw.a.run.app/member/manage',
        data: {
          "email": this.email,
          "action": action
        }
       });
       if(request){
        if(request['data']['message']){
          alert(request['data']['message']);
        }
       }
    }
  }
}
</script>