<template>
  <!-- FOOTER-3
============================================= -->
  <footer id="footer-3" class="pt-100 footer">
    <div class="container">


      <!-- FOOTER CONTENT -->
      <div class="row">


        <!-- FOOTER LOGO -->
        <div class="col-xl-3">
          <div class="footer-info">
            <img class="footer-logo" src="images/logo-purple-white.png" alt="footer-logo">
          </div>
        </div>


        <!-- FOOTER LINKS -->
        <div class="col-sm-4 col-md-3 col-xl-2">
          <div class="footer-links fl-1">

            <!-- Title -->
            <h6 class="s-17 w-700">Company</h6>

            <!-- Links -->
            <ul class="foo-links clearfix">
              <li>
                <p><a href="about.html">About Us</a></p>
              </li>
              <li>
                <p><a href="careers.html">Our Story</a></p>
              </li>
              <li>
                <p><a href="contacts.html">Contact Us</a></p>
              </li>
            </ul>

          </div>
        </div> <!-- END FOOTER LINKS -->


        <!-- FOOTER LINKS -->
        <div class="col-sm-4 col-md-3 col-xl-2">
          <div class="footer-links fl-2">

            <!-- Title -->
            <h6 class="s-17 w-700">Our Services</h6>

            <!-- Links -->
            <ul class="foo-links clearfix">
              <li>
                <p><a href="features.html">Integration</a></p>
              </li>
              <li>
                <p><a href="reviews.html">Customers</a></p>
              </li>
              <li>
                <p><a href="pricing-1.html">Pricing</a></p>
              </li>
              <li>
                <p><a href="help-center.html">Help Center</a></p>
              </li>
            </ul>

          </div>
        </div> <!-- END FOOTER LINKS -->


        <!-- FOOTER LINKS -->
        <div class="col-sm-4 col-md-3 col-xl-2">
          <div class="footer-links fl-3">

            <!-- Title -->
            <h6 class="s-17 w-700">Legal</h6>

            <!-- Links -->
            <ul class="foo-links clearfix">
              <li>
                <p><a href="terms.html">Terms of Use</a></p>
              </li>
              <li>
                <p><a href="privacy.html">Privacy Policy</a></p>
              </li>
              <li>
                <p><a href="cookies.html">Cookie Policy</a></p>
              </li>
              <li>
                <p><a href="#">Site Map</a></p>
              </li>
            </ul>

          </div>
        </div> <!-- END FOOTER LINKS -->


        <!-- FOOTER LINKS -->
        <div class="col-sm-6 col-md-3">
          <div class="footer-links fl-4">

            <!-- Title -->
            <h6 class="s-17 w-700">Connect With Us</h6>

            <!-- Mail Link -->
            <p class="footer-mail-link ico-25">
              <a href="mailto:yourdomain@mail.com">info@unftl.com</a><br>
              <a href="tel:+41766479445">Whatsapp: +41766479445</a>
            </p>

            <!-- Social Links -->
            <ul class="footer-socials ico-25 text-center clearfix">
              <li><a target="_blank" href="https://www.facebook.com/UNFTL"><span class="flaticon-facebook"></span></a></li>
              <li><a target="_blank" href="https://www.instagram.com/un_freight.trading.logistics?igsh=MXRhdDNqOG5jYm9tOQ=="><span class="flaticon-instagram"></span></a></li>
              <li><a target="_blank" href="https://www.linkedin.com/in/vittoriod/"><span class="flaticon-linkedin"></span></a></li>
            </ul>

          </div>
        </div> <!-- END FOOTER LINKS -->


      </div> <!-- END FOOTER CONTENT -->


      <hr> <!-- FOOTER DIVIDER LINE -->


      <!-- BOTTOM FOOTER -->
      <div class="bottom-footer">
        <div class="row row-cols-1 row-cols-md-2 d-flex align-items-center">


          <!-- FOOTER COPYRIGHT -->
          <div class="col">
            <div class="footer-copyright">
              <p class="p-sm">&copy; 2024 United Nations. <span>All Rights Reserved</span></p>
            </div>
          </div>



        </div> <!-- End row -->
      </div> <!-- END BOTTOM FOOTER -->


    </div> <!-- End container -->
  </footer> <!-- END FOOTER-3 -->
</template>
<script>
export default {
  name: 'FooterComponent',
  data() {
    return {
      auth: false
    }
  },
  mounted() {
    const user = sessionStorage.getItem("email");
    const home = sessionStorage.getItem("home");
    if (user && home) {
      this.auth = true;
    } else {
      this.auth = false;
    }
  },
}
</script>