<template>

  <!-- PRELOADER SPINNER
		============================================= -->
  <div id="loading" class="loading--theme">
    <div id="loading-center"><span class="loader"></span></div>
  </div>




  <!-- PAGE CONTENT
		============================================= -->
  <div id="page" class="page font--jakarta">




    <HeaderNavigation />




    <!-- HERO-19
============================================= -->
    <section id="hero-19" class="blur--purple gr--ghost hero-section">
      <div style="background:url('images/maxresdefault.jpg');position:absolute;top:0px;left:0px;opacity: 0.1;width:100%;height:786px;background-size:cover;"></div>
      <div class="container text-center">

        <!-- HERO TEXT -->
        <div class="row justify-content-center">
          <div class="col-md-10 col-lg-9">
            <div class="hero-19-txt">

              <!-- Title -->
              <h2 class="s-56 w-700" style="text-transform:uppercase">Together is better,<br><strong style="color:#fdf100">United</strong> is a privilege!</h2>

              <!-- Text -->
              <p class="p-xl">UN is an <strong>exclusive network</strong> of freight <strong>forwarders, trading and logistics</strong> companies.</p>
              <!-- Buttons -->
              <div class="btns-group">
                <a href="#/member" class="btn r-04 btn--theme hover--theme">Become a member</a>
              </div>

            </div>
          </div>
        </div> <!-- END HERO TEXT -->


        <!-- BRANDS CAROUSEL -->
        <div id="brands-1" class="py-90">
          <div class="row">
            <div class="col text-center">
              <div class="owl-carousel brands-carousel-6">

                <!-- BRAND LOGO IMAGE -->
                <div class="brand-logo">
                  <a href="#"><img class="img-fluid" src="images/founders/5.png" alt="brand-logo"></a>
                </div>


                <!-- BRAND LOGO IMAGE -->
                <div class="brand-logo">
                  <a href="#"><img class="img-fluid" src="images/founders/6.png" alt="brand-logo"></a>
                </div>


                <!-- BRAND LOGO IMAGE -->
                <div class="brand-logo">
                  <a href="#"><img class="img-fluid" src="images/founders/7.png" alt="brand-logo"></a>
                </div>


                <!-- BRAND LOGO IMAGE -->
                <div class="brand-logo">
                  <a href="#"><img class="img-fluid" src="images/founders/8.png" alt="brand-logo"></a>
                </div>

                <div class="brand-logo">
                  <a href="#"><img class="img-fluid" src="images/founders/10.png" alt="brand-logo"></a>
                </div>

                <div class="brand-logo">
                  <a href="#"><img class="img-fluid" src="images/founders/11.png" alt="brand-logo"></a>
                </div>

                <div class="brand-logo">
                  <a href="#"><img class="img-fluid" src="images/founders/12.png" alt="brand-logo"></a>
                </div>


              </div>
            </div>
          </div> <!-- End row -->
        </div> <!-- END BRANDS CAROUSEL -->


      </div> <!-- End container -->
    </section> <!-- END HERO-19 -->







    <!-- DIVIDER LINE -->
    <hr class="divider">




    <!-- TEXT CONTENT
============================================= -->
    <section id="lnk-1" class="pt-100 ct-02 content-section division">
      <div class="container">


        <!-- SECTION CONTENT (ROW) -->
        <div class="row d-flex align-items-center">


          <!-- IMAGE BLOCK -->
          <div class="col-md-6">
            <div class="img-block left-column wow fadeInRight">
              <a href="https://youtu.be/6UQps-6N8XI" class="video-popup1"><img class="img-fluid" src="images/home_fix_2.png" alt="content-image"></a>
            </div>
          </div>


          <!-- TEXT BLOCK -->
          <div class="col-md-6">
            <div class="txt-block right-column wow fadeInLeft">

              <!-- Section ID -->
              <span class="section-id">WHO WE ARE</span>

              <!-- Title -->
              <h2 class="s-46 w-700">An innovative and <strong style="color:#fdf100">global approach!</strong></h2>

              <!-- Text -->
              <p>The name United Nations reflects an inclusive and global approach, indicating that our group represents a wide range of countries and cultures, this helps in <strong>showcasing our international presence and diversity.</strong>
              </p>

              <!-- CONTENT BOX #1 -->
              <div class="cbox-1 ico-15">

                <div class="ico-wrap color--theme">
                  <div class="cbox-1-ico"><span class="flaticon-check"></span></div>
                </div>

                <div class="cbox-1-txt">
                  <p>Emphasize the values of unity, cooperation, and shared goals among our members, which <strong style="color:#fdf100">are crucial</strong> in the logistics and freight industry.</p>
                </div>

              </div>

              <!-- CONTENT BOX #2 -->
              <div class="cbox-1 ico-15">

                <div class="ico-wrap color--theme">
                  <div class="cbox-1-ico"><span class="flaticon-check"></span></div>
                </div>

                <div class="cbox-1-txt">
                  <p>Before founding the network, we tested our organizational and creative skills <strong style="color:#fdf100">for 5 years.</strong>
                  </p>
                </div>

              </div>

              <!-- CONTENT BOX #3 -->
              <div class="cbox-1 ico-15">

                <div class="ico-wrap color--theme">
                  <div class="cbox-1-ico"><span class="flaticon-check"></span></div>
                </div>

                <div class="cbox-1-txt">
                  <p class="mb-0">Our trademark: the massive fair booth, setting the trend for the industry and showing how teamwork and collaboration <strong style="color:#fdf100">can make big dreams a reality.</strong></p>
                </div>

              </div>

            </div>
          </div> <!-- END TEXT BLOCK -->


        </div> <!-- END SECTION CONTENT (ROW) -->


      </div> <!-- End container -->
    </section> <!-- END TEXT CONTENT -->

    <!-- FEATURES-7
			============================================= -->
    <section id="features-7" class="pt-100 features-section division">
      <div class="container">


        <!-- SECTION TITLE -->
        <div class="row justify-content-center">
          <div class="col-md-10 col-lg-9">
            <div class="section-title mb-70">

              <!-- Title -->
              <h2 class="s-50 w-700">Everything in <strong style="color:#fdf100">One Place</strong></h2>

              <!-- Text -->
              <p class="s-21 color--grey">Together is better!</p>

            </div>
          </div>
        </div>

        <!-- FEATURES-7 WRAPPER -->
        <div class="fbox-wrapper text-center">
          <div class="row row-cols-1 row-cols-md-2 row-cols-lg-4">

            <!-- FEATURE BOX #3 Promotion -->
            <div class="col">
              <div class="fbox-7 fb-3 r-12 wow fadeInUp">

                <!-- Icon -->
                <div class="fbox-ico ico-50">
                  <div class="shape-ico color--theme">

                    <!-- Vector Icon -->
                    <span class="flaticon-taxes"></span>

                    <!-- Shape -->
                    <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                      <path d="M69.8,-23C76.3,-2.7,57.6,25.4,32.9,42.8C8.1,60.3,-22.7,67,-39.1,54.8C-55.5,42.7,-57.5,11.7,-48.6,-11.9C-39.7,-35.5,-19.8,-51.7,5.9,-53.6C31.7,-55.6,63.3,-43.2,69.8,-23Z" transform="translate(100 100)"></path>
                    </svg>

                  </div>
                </div> <!-- End Icon -->

                <!-- Text -->
                <div class="fbox-txt">
                  <h6 class="s-20 w-700">Promotion</h6>
                  <p class="p-sm">Promotion of each agent, utilizing a <strong style="text-decoration: underline;">database of over 165.000 email contacts.</strong></p>
                </div>

              </div>
            </div> <!-- END FEATURE BOX #3 -->

            <!-- FEATURE BOX #1 Conferences -->
            <div class="col">
              <div class="fbox-7 fb-1 r-12 wow fadeInUp">

                <!-- Icon -->
                <div class="fbox-ico ico-50">
                  <div class="shape-ico color--theme">

                    <!-- Vector Icon -->
                    <span class="flaticon-graphics"></span>

                    <!-- Shape -->
                    <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                      <path d="M69.8,-23C76.3,-2.7,57.6,25.4,32.9,42.8C8.1,60.3,-22.7,67,-39.1,54.8C-55.5,42.7,-57.5,11.7,-48.6,-11.9C-39.7,-35.5,-19.8,-51.7,5.9,-53.6C31.7,-55.6,63.3,-43.2,69.8,-23Z" transform="translate(100 100)" />
                    </svg>

                  </div>
                </div> <!-- End Icon -->

                <!-- Text -->
                <div class="fbox-txt">
                  <h6 class="s-20 w-700">Conferences</h6>
                  <p class="p-sm">Organized in countries that are <strong style="text-decoration: underline;">easily accessible</strong>, with the main objective of developing business.</p>
                </div>

              </div>
            </div> <!-- END FEATURE BOX #1 -->

            <!-- FEATURE BOX #1Prevention -->
            <div class="col">
              <div class="fbox-7 fb-1 r-12 wow fadeInUp">

                <!-- Icon -->
                <div class="fbox-ico ico-50">
                  <div class="shape-ico color--theme">

                    <!-- Vector Icon -->
                    <span class="flaticon-layers-1"></span>

                    <!-- Shape -->
                    <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                      <path d="M69.8,-23C76.3,-2.7,57.6,25.4,32.9,42.8C8.1,60.3,-22.7,67,-39.1,54.8C-55.5,42.7,-57.5,11.7,-48.6,-11.9C-39.7,-35.5,-19.8,-51.7,5.9,-53.6C31.7,-55.6,63.3,-43.2,69.8,-23Z" transform="translate(100 100)" />
                    </svg>

                  </div>
                </div> <!-- End Icon -->

                <!-- Text -->
                <div class="fbox-txt">
                  <h6 class="s-20 w-700">Prevention</h6>
                  <p class="p-sm">Carrying out careful intelligence action, <strong style="text-decoration: underline;">checking all members</strong> thoroughly and periodically.</p>
                </div>

              </div>
            </div> <!-- END FEATURE BOX #1 -->

            <!-- FEATURE BOX #3 Trade Lanes -->
            <div class="col">
              <div class="fbox-7 fb-3 r-12 wow fadeInUp">

                <!-- Icon -->
                <div class="fbox-ico ico-50">
                  <div class="shape-ico color--theme">

                    <!-- Vector Icon -->
                    <span class="flaticon-prioritize"></span>

                    <!-- Shape -->
                    <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                      <path d="M69.8,-23C76.3,-2.7,57.6,25.4,32.9,42.8C8.1,60.3,-22.7,67,-39.1,54.8C-55.5,42.7,-57.5,11.7,-48.6,-11.9C-39.7,-35.5,-19.8,-51.7,5.9,-53.6C31.7,-55.6,63.3,-43.2,69.8,-23Z" transform="translate(100 100)" />
                    </svg>

                  </div>
                </div> <!-- End Icon -->

                <!-- Text -->
                <div class="fbox-txt">
                  <h6 class="s-20 w-700">Trade Lanes</h6>
                  <p class="p-sm">Trade lanes and set up of HUBs in different regions to <strong style="text-decoration: underline;">improve members' participation.</strong></p>
                </div>

              </div>
            </div> <!-- END FEATURE BOX #3 -->


          </div> <!-- End row -->
        </div> <!-- END FEATURES-7 WRAPPER -->


      </div> <!-- End container -->
    </section> <!-- END FEATURES-7 -->

    <!-- BOX CONTENT
============================================= -->
    <section class="pt-100 ws-wrapper content-section">
      <div class="container">
        <div class="bc-1-wrapper bg--white-400 bg--fixed r-16">
          <div class="section-overlay">
            <div class="row d-flex align-items-center">


              <!-- TEXT BLOCK -->
              <div class="col-md-6 order-last order-md-2">
                <div class="txt-block left-column wow fadeInRight">

                  <!-- Section ID -->
                  <span class="section-id">One-Stop Solution</span>

                  <!-- Title -->
                  <h2 class="s-46 w-700">Discover the future of networking</h2>

                  <!-- List -->
                  <ul class="simple-list">

                    <li class="list-item">
                      <p>Be the protagonist: make your company visible thanks to our personalized marketing program
                      </p>
                    </li>

                    <li class="list-item">
                      <p class="mb-0">Be present in the major exhibitions around the world
                      </p>
                    </li>

                  </ul>

                  <!-- Button -->
                  <a href="#/member" class="btn btn-sm r-04 btn--theme hover--tra-black">
                    Become a member
                  </a>

                </div>
              </div> <!-- END TEXT BLOCK -->


              <!-- IMAGE BLOCK -->
              <div class="col-md-6 order-first order-md-2">
                <div class="img-block right-column wow fadeInLeft">
                  <img class="img-fluid" src="images/home_fix_3.png" alt="content-image">
                </div>
              </div>


            </div> <!-- End row -->
          </div> <!-- End section overlay -->
        </div> <!-- End content wrapper -->
      </div> <!-- End container -->
    </section> <!-- END BOX CONTENT -->









    <!-- DIVIDER LINE -->
    <hr class="divider">


    <!-- FEATURES-2
============================================= -->
    <section id="features-2" class="py-100 features-section division">
      <div class="container">


        <!-- SECTION TITLE -->
        <div class="row justify-content-center">
          <div class="col-md-10 col-lg-9">
            <div class="section-title mb-80">

              <!-- Title -->
              <h2 class="s-50 w-700">Networking Hub: <strong style="color:#fdf100">Connecting the Global Logistics Community</strong></h2>

              <!-- Text -->
              <p class="s-21 color--grey">Streamlining Connections, Relationships, and Information Sharing at Industry Events</p>

            </div>
          </div>
        </div>


        <!-- FEATURES-2 WRAPPER -->
        <div class="fbox-wrapper text-center">
          <div class="row row-cols-1 row-cols-md-3 rows-2">


            <!-- FEATURE BOX #1 -->
            <div class="col">
              <div class="fbox-2 fb-1 wow fadeInUp">

                <!-- Image -->
                <div class="fbox-img gr--whitesmoke h-170">
                  <img class="img-fluid" src="images/xx1.png" alt="feature-image">
                </div>

                <!-- Text -->
                <div class="fbox-txt">
                  <h6 class="s-22 w-700">Connections</h6>
                  <p style="padding: 10%;padding-top: 0px;padding-bottom: 0px;">Providing a <strong style="color:#fdf100">central meeting place</strong> to foster interactions, suggestions, and friendships during the next events.</p>
                </div>

              </div>
            </div> <!-- END FEATURE BOX #1 -->


            <!-- FEATURE BOX #2 -->
            <div class="col">
              <div class="fbox-2 fb-2 wow fadeInUp">

                <!-- Image -->
                <div class="fbox-img gr--whitesmoke h-170">
                  <img class="img-fluid" src="images/xx2.png" alt="feature-image">
                </div>

                <!-- Text -->
                <div class="fbox-txt">
                  <h6 class="s-22 w-700">Relationship</h6>
                  <p style="padding: 10%;padding-top: 0px;padding-bottom: 0px;">Meet more friends from same industry, setup a <strong style="color:#fdf100">fantastic relationship.</strong> No wasting time, straight to the point, straight to the business.</p>
                </div>

              </div>
            </div> <!-- END FEATURE BOX #2 -->


            <!-- FEATURE BOX #3 -->
            <div class="col">
              <div class="fbox-2 fb-3 wow fadeInUp">

                <!-- Image -->
                <div class="fbox-img gr--whitesmoke h-170">
                  <img class="img-fluid" src="images/xx3.png" alt="feature-image">
                </div>

                <!-- Text -->
                <div class="fbox-txt">
                  <h6 class="s-22 w-700">Sharing</h6>
                  <p style="padding: 10%;padding-top: 0px;padding-bottom: 0px;">Share your information within this group, <strong style="color:#fdf100">promote your upcoming exhibitions</strong>, and let your colleagues know you're ready to connect.</p>
                </div>

              </div>
            </div> <!-- END FEATURE BOX #3 -->




          </div> <!-- End row -->
        </div> <!-- END FEATURES-2 WRAPPER -->


      </div> <!-- End container -->
    </section> <!-- END FEATURES-2 -->




    <!-- DIVIDER LINE -->
    <hr class="divider">


    <!-- TEXT CONTENT
			============================================= -->
    <section style="display:none;" class="py-100 ct-02 content-section division">
      <div class="container">


        <!-- SECTION CONTENT (ROW) -->
        <div class="row d-flex align-items-center">


          <!-- IMAGE BLOCK -->
          <div class="col-md-6">
            <div class="img-block left-column wow fadeInRight">
              <img class="img-fluid" src="images/094_03072024_UN_Intermodal2024_Day3_©RicardoMatsukawa-5992.jpg" alt="content-image">
            </div>
          </div>


          <!-- TEXT BLOCK -->
          <div class="col-md-6">
            <div class="txt-block right-column wow fadeInLeft">


              <!-- CONTENT BOX #1 -->
              <div class="cbox-2 process-step">

                <!-- Icon -->
                <div class="ico-wrap">
                  <div class="cbox-2-ico bg--theme color--white">1</div>
                  <span class="cbox-2-line"></span>
                </div>

                <!-- Text -->
                <div class="cbox-2-txt">
                  <h5 class="s-22 w-700">Make your time more <strong style="color:#fdf100">profitable</strong></h5>
                  <p>Instead of wasting time, in very long and random meetings, better to have a full schedule meetings pre-planned.</p>
                </div>

              </div> <!-- END CONTENT BOX #1 -->


              <!-- CONTENT BOX #2 -->
              <div class="cbox-2 process-step">

                <!-- Icon -->
                <div class="ico-wrap">
                  <div class="cbox-2-ico bg--theme color--white">2</div>
                  <span class="cbox-2-line"></span>
                </div>

                <!-- Text -->
                <div class="cbox-2-txt">
                  <h5 class="s-22 w-700">Make your visit more <strong style="color:#fdf100">enjoyable</strong></h5>
                  <p>Stay 3 days with all people that are looking for more connections and opportunities, just like you.
                  </p>
                </div>

              </div> <!-- END CONTENT BOX #2 -->


              <!-- CONTENT BOX #3 -->
              <div class="cbox-2 process-step">

                <!-- Icon -->
                <div class="ico-wrap">
                  <div class="cbox-2-ico bg--theme color--white">3</div>
                </div>

                <!-- Text -->
                <div class="cbox-2-txt">
                  <h5 class="s-22 w-700">Make your experience <strong style="color:#fdf100">unique</strong></h5>
                  <p class="mb-0">Have a place to rest and meet your contacts, we move on together.
                  </p>
                </div>

              </div> <!-- END CONTENT BOX #3 -->

              <!-- CONTENT BOX #4 -->
              <div class="cbox-2 process-step">

                <!-- Icon -->
                <div class="ico-wrap">
                  <div class="cbox-2-ico bg--theme color--white">4</div>
                </div>

                <!-- Text -->
                <div class="cbox-2-txt">
                  <h5 class="s-22 w-700">Enjoy <strong style="color:#fdf100">full day</strong></h5>
                  <p class="mb-0">We will have “after event” parties, every night we will have some fun …. The day will be much longer ! The booth will be the “house of freight forwarder”, your home for 3 days !
                  </p>
                </div>

              </div> <!-- END CONTENT BOX #3 -->


            </div>
          </div> <!-- END TEXT BLOCK -->


        </div> <!-- END SECTION CONTENT (ROW) -->


      </div> <!-- End container -->
    </section> <!-- END TEXT CONTENT -->

    <!-- DIVIDER LINE -->
    <hr class="divider">


    <!-- ABOUT-2
			============================================= -->
    <div id="about-2" class="rel pt-100 about-section division">


      <!-- ABOUT-2 IMAGES -->
      <div class="container-fluid">
        <div class="row">


          <!-- IMAGES-1 -->
          <div class="col-md-5">
            <div class="text-end">


              <!-- IMAGE-1 -->
              <div class="about-2-img a-2-1 r-12">
                <img class="img-fluid" src="mosaico/1.jpg" alt="about-image">
              </div>


              <!-- IMAGE-2 -->
              <div class="about-2-img a-2-2 r-12">
                <img class="img-fluid" src="mosaico/2.jpg" alt="about-image">
              </div>


            </div>
          </div> <!-- END IMAGES-1 -->


          <!-- IMAGES-2 -->
          <div class="col-md-7">


            <!-- IMAGE-3 -->
            <div class="about-2-img a-2-3 r-12">
              <img class="img-fluid" src="mosaico/3.jpg" alt="about-image">
            </div>


            <div class="row">

              <!-- TEXT -->
              <div class="col-md-7 col-lg-6">
                <div class="a2-txt bg--black-400 pattern-01 bg--fixed color--white r-12">

                  <!-- Icon -->
                  <div class="a2-txt-quote ico-40 o-20">
                    <span class="flaticon-quote"></span>
                  </div>

                  <!-- Text -->
                  <p>The energy of UN people is great, everybody is so focused in wanting to do business and everybody’s friendly and nice.  
                  </p>

                  <!-- Author -->
                  <p class="a2-txt-author">Renato Garbin <span>Journey Freight, Canada</span></p>

                </div>
              </div>

              <!-- IMAGE-4 -->
              <div class="col-md-5 col-lg-6">
                <div class="about-2-img a-2-4 r-12">
                  <img class="img-fluid" src="mosaico/4.jpg" alt="about-image">
                </div>
              </div>

            </div> <!-- End row -->


          </div> <!-- END IMAGES-2 -->


        </div> <!-- End row -->
      </div> <!-- END ABOUT-2 IMAGES -->


    </div> <!-- END ABOUT-2 -->




    <!-- TESTIMONIALS-1
============================================= -->
    <section id="reviews-1" class="py-100 mt-50 shape--06 shape--gr-whitesmoke reviews-section">
      <div class="container">


        <!-- SECTION TITLE -->
        <div class="row justify-content-center">
          <div class="col-md-10 col-lg-9">
            <div class="section-title mb-70">

              <!-- Title -->
              <h2 class="s-50 w-700">Our Happy members</h2>

              <!-- Text -->
              <p class="s-21 color--grey">Ligula risus auctor tempus magna feugiat lacinia.</p>

            </div>
          </div>
        </div>


        <!-- TESTIMONIALS CONTENT -->
        <div class="row">
          <div class="col">
            <div class="owl-carousel owl-theme reviews-1-wrapper">


              <!-- TESTIMONIAL #1 -->
              <div class="review-1 bg--white-100 block-shadow r-08">

                <!-- Quote Icon -->
                <div class="review-ico ico-65"><span class="flaticon-quote"></span></div>

                <!-- Text -->
                <div class="review-txt">

                  <!-- Text -->
                  <p>The marketing UN is doing everyday for us puts our company in another level.
                  </p>

                  <!-- Author -->
                  <div class="author-data clearfix">

                    <!-- Avatar -->
                    <div class="review-avatar">
                      <img src="images/feedback/001.jpg" alt="review-avatar">
                    </div>

                    <!-- Data -->
                    <div class="review-author">
                      <h6 class="s-18 w-700">Paulo Ferreira</h6>
                      <p class="p-sm">Multicargo | Portugal</p>
                    </div>

                  </div> <!-- End Author -->

                </div> <!-- End Text -->

              </div> <!-- END TESTIMONIAL #1 -->


              <!-- TESTIMONIAL #2 -->
              <div class="review-1 bg--white-100 block-shadow r-08">

                <!-- Quote Icon -->
                <div class="review-ico ico-65"><span class="flaticon-quote"></span></div>

                <!-- Text -->
                <div class="review-txt">

                  <!-- Text -->
                  <p>I think UN is really supportive, I just gave a little bit of informations and now I see my face out there being marketed in the network, I think it’s really impressive and it’s a huge help for the members.
                  </p>

                  <!-- Author -->
                  <div class="author-data clearfix">

                    <!-- Avatar -->
                    <div class="review-avatar">
                      <img src="images/feedback/STEFANIK.jpg" alt="review-avatar">
                    </div>

                    <!-- Data -->
                    <div class="review-author">
                      <h6 class="s-18 w-700">Lawton Stefanik</h6>
                      <p class="p-sm">Navia | USA</p>
                    </div>

                  </div> <!-- End Author -->

                </div> <!-- End Text -->

              </div> <!-- END TESTIMONIAL #2 -->


              <!-- TESTIMONIAL #3 -->
              <div class="review-1 bg--white-100 block-shadow r-08">

                <!-- Quote Icon -->
                <div class="review-ico ico-65"><span class="flaticon-quote"></span></div>

                <!-- Text -->
                <div class="review-txt">

                  <!-- Text -->
                  <p>UN is very fair to everyone and very opened, all the members can share their ideas, it’s very different from other associations.
                  </p>

                  <!-- Author -->
                  <div class="author-data clearfix">

                    <!-- Avatar -->
                    <div class="review-avatar">
                      <img src="images/feedback/20200427094100.jpg" alt="review-avatar">
                    </div>

                    <!-- Data -->
                    <div class="review-author">
                      <h6 class="s-18 w-700">Lizzy Liu</h6>
                      <p class="p-sm">CGML | China</p>
                    </div>

                  </div> <!-- End Author -->

                </div> <!-- End Text -->

              </div> <!-- END TESTIMONIAL #3 -->


              <!-- TESTIMONIAL #4 -->
              <div class="review-1 bg--white-100 block-shadow r-08">

                <!-- Quote Icon -->
                <div class="review-ico ico-65"><span class="flaticon-quote"></span></div>

                <!-- Text -->
                <div class="review-txt">

                  <!-- Text -->
                  <p>UN is a very dynamic group, very energetic and you can definitely tell that there’s a good camaraderie within the group and a lot of opportunities.
                  </p>

                  <!-- Author -->
                  <div class="author-data clearfix">

                    <!-- Avatar -->
                    <div class="review-avatar">
                      <img src="images/feedback/image.png" alt="review-avatar">
                    </div>

                    <!-- Data -->
                    <div class="review-author">
                      <h6 class="s-18 w-700">Lance Rule</h6>
                      <p class="p-sm">Rule Logistics | USA</p>
                    </div>

                  </div> <!-- End Author -->

                </div> <!-- End Text -->

              </div> <!-- END TESTIMONIAL #4 -->


              <!-- TESTIMONIAL #6 -->
              <div class="review-1 bg--white-100 block-shadow r-08">

                <!-- Quote Icon -->
                <div class="review-ico ico-65"><span class="flaticon-quote"></span></div>

                <!-- Text -->
                <div class="review-txt">

                  <!-- Text -->
                  <p>What we have noticed In UN is the support. We are grateful to be a part of this noble network.
                  </p>

                  <!-- Author -->
                  <div class="author-data clearfix">

                    <!-- Avatar -->
                    <div class="review-avatar">
                      <img src="images/feedback/lorraine.png" alt="review-avatar">
                    </div>

                    <!-- Data -->
                    <div class="review-author">
                      <h6 class="s-18 w-700">Lorraine Harris</h6>
                      <p class="p-sm">LTN LOGISTICS LTD | Jamaica</p>
                    </div>

                  </div> <!-- End Author -->

                </div> <!-- End Text -->

              </div> <!-- END TESTIMONIAL #6 -->


              <!-- TESTIMONIAL #7 -->
              <div class="review-1 bg--white-100 block-shadow r-08">

                <!-- Quote Icon -->
                <div class="review-ico ico-65"><span class="flaticon-quote"></span></div>

                <!-- Text -->
                <div class="review-txt">

                  <!-- Text -->
                  <p>The energy of UN people is great, everybody is so focused in wanting to do business and everybody’s friendly and nice.  
                  </p>

                  <!-- Author -->
                  <div class="author-data clearfix">

                    <!-- Avatar -->
                    <div class="review-avatar">
                      <img src="images/feedback/garbin.png" alt="review-avatar">
                    </div>

                    <!-- Data -->
                    <div class="review-author">
                      <h6 class="s-18 w-700">Renato Garbin</h6>
                      <p class="p-sm">Journey Freight | Canada</p>
                    </div>

                  </div> <!-- End Author -->

                </div> <!-- End Text -->

              </div> <!-- END TESTIMONIAL #7 -->


              <!-- TESTIMONIAL #8 -->
              <div class="review-1 bg--white-100 block-shadow r-08">

                <!-- Quote Icon -->
                <div class="review-ico ico-65"><span class="flaticon-quote"></span></div>

                <!-- Text -->
                <div class="review-txt">

                  <!-- Text -->
                  <p>En UN más que una aleanza se ha generado una amistad que hace que los negocios se hacen con una energia más fuerte!
                  </p>

                  <!-- Author -->
                  <div class="author-data clearfix">

                    <!-- Avatar -->
                    <div class="review-avatar">
                      <img src="images/feedback/COLOMBIA.png" alt="review-avatar">
                    </div>

                    <!-- Data -->
                    <div class="review-author">
                      <h6 class="s-18 w-700">Mabel Amarillo</h6>
                      <p class="p-sm">Transintercargo | Colombia</p>
                    </div>

                  </div> <!-- End Author -->

                </div> <!-- End Text -->

              </div> <!-- END TESTIMONIAL #8 -->


            </div>
          </div>
        </div> <!-- END TESTIMONIALS CONTENT -->


      </div> <!-- End container -->
    </section> <!-- END TESTIMONIALS-1 -->


    <!-- FAQs-3
			============================================= -->
    <section id="faqs-3" class="pt-100 faqs-section" style="display:none;">
      <div class="container">


        <!-- SECTION TITLE -->
        <div class="row justify-content-center">
          <div class="col-md-10 col-lg-9">
            <div class="section-title mb-70">

              <!-- Title -->
              <h2 class="s-50 w-700">Questions & Answers</h2>

              <!-- Text -->
              <p class="s-21 color--grey">Ligula risus auctor tempus magna feugiat lacinia.</p>

            </div>
          </div>
        </div>


        <!-- FAQs-3 QUESTIONS -->
        <div class="faqs-3-questions">
          <div class="row">


            <!-- QUESTIONS HOLDER -->
            <div class="col-lg-6">
              <div class="questions-holder">


                <!-- QUESTION #1 -->
                <div class="question mb-35 wow fadeInUp">

                  <!-- Question -->
                  <h5 class="s-22 w-700"><span>1.</span> Do we provide membership list?</h5>

                  <!-- Answer -->
                  <p class="color--grey">No, at the moment we don't share our member directory
                  </p>

                </div>


                <!-- QUESTION #2 -->
                <div class="question mb-35 wow fadeInUp">

                  <!-- Question -->
                  <h5 class="s-22 w-700"><span>2.</span> How many global members do we have?</h5>

                  <!-- Answer -->
                  <p class="color--grey">Our network has just been founded, so we are still looking for founding members.
                  </p>

                </div>

                <!-- QUESTION #6 -->
                <div class="question mb-35 wow fadeInUp">

                  <!-- Question -->
                  <h5 class="s-22 w-700"><span>3.</span> What are financial protection procedures?</h5>

                  <!-- Answer -->
                  <p class="color--grey">We offer “insolvency protection fund” governed by
                    the conditions reported in the membership
                    application.
                  </p>

                </div>


                <!-- QUESTION #6 -->
                <div class="question mb-35 wow fadeInUp">

                  <!-- Question -->
                  <h5 class="s-22 w-700"><span>4.</span> Is our network exclusive? If no, how many
                    members do we admit per country?</h5>

                  <!-- Answer -->
                  <p class="color--grey">We do not believe in exclusivity in any way. The
                    limitation is set at a minimum of 2 members for all
                    countries, regardless of size.
                    The maximum limit at the moment is 2 members for
                    small countries, 4 members for medium countries, 8
                    members for the 3 main countries
                    (CHINA/INDIA/USA).
                  </p>

                </div>





              </div>
            </div> <!-- END QUESTIONS HOLDER -->


            <!-- QUESTIONS WRAPPER -->
            <div class="col-lg-6">
              <div class="questions-holder">






                <!-- QUESTION #6 -->
                <div class="question mb-35 wow fadeInUp">

                  <!-- Question -->
                  <h5 class="s-22 w-700"><span>5.</span> When and where is our next conference?</h5>

                  <!-- Answer -->
                  <p class="color--grey">You are invited to follow the information reported on
                    our website, on our media channels and via the
                    WhatsApp group for further updates
                  </p>

                </div>


                <!-- QUESTION #6 -->
                <div class="question mb-35 wow fadeInUp">

                  <!-- Question -->
                  <h5 class="s-22 w-700"><span>6.</span> What differentiate our network from others?</h5>

                  <ul class="simple-list color--grey">

                    <li class="list-item">
                      Before founding the network, we tested our
                      organizational and creative skills for 5 years.</li>
                    <li class="list-item">Innovation to create business connections and
                      interactions.</li>
                    <li class="list-item"> Member-focused, intense, constant and
                      transversal promotion among a database of
                      +165,000 contacts.</li>
                    <li class="list-item"> Strong presence during worldwide
                      exhibitions/commercial trade fairs.</li>
                    <li class="list-item">We will introduce and develop “TRADING”
                      segment between our members.</li>
                  </ul>

                </div>


              </div>
            </div> <!-- END QUESTIONS HOLDER -->


          </div> <!-- End row -->
        </div> <!-- END FAQs-3 QUESTIONS -->


        <!-- MORE QUESTIONS LINK -->
        <div class="row">
          <div class="col">
            <div class="more-questions mt-40">
              <div class="more-questions-txt bg--white-400 r-100">
                <p class="p-lg">Have any questions?
                  <a href="contacts.html" class="color--theme">Get in Touch</a>
                </p>
              </div>
            </div>
          </div>
        </div>


      </div> <!-- End container -->
    </section> <!-- END FAQs-3 -->




    <!-- BANNER-7
============================================= -->
    <section id="banner-7" class="banner-section">
      <div class="banner-overlay py-100">
        <div class="container">


          <!-- BANNER-7 WRAPPER -->
          <div class="banner-7-wrapper">
            <div class="row justify-content-center">


              <!-- BANNER-7 TEXT -->
              <div class="col-md-8">
                <div class="banner-7-txt text-center">

                  <!-- Section ID -->
                  <span class="section-id">Get Started Now</span>

                  <!-- Title -->
                  <h2 class="s-48 w-700">Expand your business globally<br><strong style="color:#fdf100">Become a member!</strong></h2>

                  <!-- Buttons -->
                  <div class="btns-group">
                    <a href="#/member" class="btn r-04 btn--theme hover--theme">Become a member
                    </a>
                    <a href="#/member" class="btn r-04 btn--tra-black hover--theme">Discover pricing</a>
                  </div>

                </div>
              </div>


            </div> <!-- End row -->
          </div> <!-- END BANNER-7 WRAPPER -->


        </div> <!-- End container -->
      </div> <!-- End banner overlay -->
    </section> <!-- END BANNER-7 -->




    <!-- DIVIDER LINE -->
    <hr class="divider">


    <FooterComponent />
    
        <!-- Backdrop div with transition for fade effect -->
      <div v-if="showBackdrop" class="backdrop">
        <div class="popup">
          <a @click="closePopup" class="close_icon"><img src="/images/9110796_x_icon.png" /></a>
          <a href="/#/events"><img src="/images/int_modal.png" /></a>
        </div>
      </div>



  </div> <!-- END PAGE CONTENT -->

</template>

<script>
import HeaderNavigation from './components/HeaderNavigation.vue';
import FooterComponent from './components/FooterComponent.vue';
export default {
  name: 'IndexView',
  components: { HeaderNavigation, FooterComponent },
  data() {
    return {
      showBackdrop: false, // Flag to control popup visibility
    };
  },
  mounted() {
    // Show the popup after 5 seconds
    setTimeout(() => {
      this.showBackdrop = true;
    }, 5000);
  },
  methods: {
    closePopup() {
      // Hide the popup when the X is clicked
      this.showBackdrop = false;
    },
  },
}
</script>