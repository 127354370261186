<template>
  <HeaderNavigation />

  <section class="ct-01 inner-page-hero content-section division">

    <div class="container">
      <div class="row d-flex align-items-center">


        <!-- TEXT BLOCK -->
        <div class="col-md-6">
          <div class="txt-block left-column color--white wow fadeInRight" style="visibility: visible; animation-name: fadeInRight;">

            <!-- Section ID -->
            <span class="section-id rounded-id bg--tra-white color--white">
              Who we are
            </span>

            <!-- Title -->
            <h2 class="s-32 w-700">UN is an SWISS-MADE NETWORK of freight <strong style="color:#fdf100">forwarders, trading and logistics</strong> companies. </h2>

            <!-- Text -->
            <p>The name United Nations reflects an inclusive and global approach, indicating that our group represents a wide range of countries and cultures, this helps in <strong style="color:#fdf100">showcasing our international presence and diversity.</strong>
              Emphasize the values of unity, cooperation, and shared goals among our members, which <strong style="color:#fdf100">are crucial</strong> in the logistics and freight industry.</p>
              <p>Before founding the network, we tested our organizational and creative skills <strong style="color:#fdf100">for 5 years.</strong></p>
              <p>Our trademark: the massive fair booth, setting the trend for the industry and showing how teamwork and collaboration <strong style="color:#fdf100">can make big dreams a reality.</strong>
            </p>

          </div>
        </div> <!-- END TEXT BLOCK -->


        <!-- IMAGE BLOCK -->
        <div class="col-md-6">
          <div class="img-block right-column wow fadeInLeft" style="visibility: visible; animation-name: fadeInLeft;">
            <img class="img-fluid" src="images/img-18.png" alt="content-image">
          </div>
        </div>


      </div> <!-- End row -->

      
    </div>

  </section> <!-- END TEXT CONTENT -->


      <!-- BANNER-7
============================================= -->
<section id="banner-7" class="banner-section">
      <div class="banner-overlay py-100">
        <div class="container">


          <!-- BANNER-7 WRAPPER -->
          <div class="banner-7-wrapper">
            <div class="row justify-content-center">


              <!-- BANNER-7 TEXT -->
              <div class="col-md-8">
                <div class="banner-7-txt text-center">

                  <!-- Section ID -->
                  <span class="section-id">Get Started Now</span>

                  <!-- Title -->
                  <h2 class="s-48 w-700">Expand your business globally<br><strong style="color:#fdf100">Become a member!</strong></h2>

                  <!-- Buttons -->
                  <div class="btns-group">
                    <a href="#/member" class="btn r-04 btn--theme hover--theme">Become a member
                    </a>
                    <a href="#/member" class="btn r-04 btn--tra-black hover--theme">Discover pricing</a>
                  </div>

                </div>
              </div>


            </div> <!-- End row -->
          </div> <!-- END BANNER-7 WRAPPER -->


        </div> <!-- End container -->
      </div> <!-- End banner overlay -->
    </section> <!-- END BANNER-7 -->

  



  <FooterComponent />


</template>

<script>
import HeaderNavigation from './components/HeaderNavigation.vue';
import FooterComponent from './components/FooterComponent.vue';
export default {
  name: 'OurStory',
  components: { HeaderNavigation, FooterComponent }
}
</script>