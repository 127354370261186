<template>
  <HeaderNavigation />
  <ProfileEdit />

</template>

<script>
import HeaderNavigation from './components/HeaderNavigation.vue';
import ProfileEdit from './components/ProfileEdit.vue';
export default {
  name: 'BecomeMember',
  components: { HeaderNavigation, ProfileEdit }
}
</script>