<template>

  <section id="contacts-1" class="pb-50 inner-page-hero contacts-section division">
    <div class="container">

      <!-- SECTION TITLE -->
      <div class="row justify-content-center">
        <div class="col-md-10 col-lg-9">
          <div class="section-title text-center mb-80">

            <!-- Title -->
            <h2 class="s-52 w-700">Edit your profile</h2>

            <!-- Text -->
            <p class="p-lg">Want to learn more about Martex, get a quote, or speak with an expert?
              Let us know what you are looking for and we’ll get back to you right away
            </p>

          </div>
        </div>
      </div>

      <section id="faqs-4" class="faqs-section division">


        <!-- TABS NAVIGATION -->
        <div class="row">
          <div class="col">
            <div class="tabs-nav tabs--theme clearfix">
              <ul class="tabs-1">

                <!-- TAB-1 LINK -->
                <li class="tab-link current ico-45 r-16" data-tab="tab-1">
                  <div class="tab-link-ico"><span class="flaticon-mechanics"></span></div>
                  <p>My information</p>
                </li>

                <!-- TAB-2 LINK -->
                <li class="tab-link ico-45 r-16" data-tab="tab-2">
                  <div class="tab-link-ico"><span class="flaticon-suit"></span></div>
                  <p>My Branch</p>
                </li>

                <!-- TAB-3 LINK -->
                <li class="tab-link ico-45 r-16" data-tab="tab-3">
                  <div class="tab-link-ico"><span class="flaticon-price-label"></span></div>
                  <p>Pricing Plans</p>
                </li>

                <!-- TAB-4 LINK -->
                <li class="tab-link ico-45 r-16" data-tab="tab-4">
                  <div class="tab-link-ico"><span class="flaticon-screenplay"></span></div>
                  <p>Service 24/h</p>
                </li>

              </ul>
            </div>
          </div>
        </div> <!-- END TABS NAVIGATION -->


        <!-- TABS CONTENT -->
        <div class="tabs-content">
          <div class="row justify-content-center">
            <div class="col-xl-10">


              <!-- TAB-1 CONTENT -->
              <div id="tab-1" class="tab-content current">
                <!-- CONTACT FORM -->
                <div class="row justify-content-center">
                  <div>
                    <div class="form-holder">
                      <form name="contactform" class="row contact-form">

                        <!-- Contact Form Input -->
                        <div class="col-md-12">
                          <p class="p-lg">Your Name: </p>
                          <span>Please enter your real name: </span>
                          <input type="text" v-model="form.name" name="name" class="form-control name" placeholder="Your Name*">
                        </div>

                        <div class="col-md-12">
                          <p class="p-lg">Your Email Address: </p>
                          <span>Please carefully check your email address for accuracy</span>
                          <input type="text" name="email" v-model="form.email" class="form-control email" placeholder="Email Address*">
                        </div>

                        <div class="col-md-12">
                          <p class="p-lg">Your password: </p>
                          <span>Please enter your real name: </span>
                          <input type="text" v-model="form.password" name="name" class="form-control name" placeholder="Your Name*">
                        </div>

                        <div class="col-md-12">
                          <p class="p-lg">Your Country: </p>
                          <span>Please enter your real name: </span>
                          <input type="text" v-model="form.country" name="name" class="form-control name" placeholder="Your Name*">
                        </div>

                        <div class="col-md-12">
                          <p class="p-lg">Your Phone Number: </p>
                          <span>Please enter your real name: </span>
                          <input type="text" v-model="form.phone" name="name" class="form-control name" placeholder="Your Name*">
                        </div>

                        <div class="col-md-12">
                          <p class="p-lg">Your Mobile Number: </p>
                          <span>Please enter your real name: </span>
                          <input type="text" v-model="form.mobile" name="name" class="form-control name" placeholder="Your Name*">
                        </div>

                        <div class="col-md-12">
                          <p class="p-lg">Your Site: </p>
                          <span>Please enter your real name: </span>
                          <input type="text" v-model="form.site" name="name" class="form-control name" placeholder="Your Name*">
                        </div>

                        <div class="col-md-12">
                          <p class="p-lg">Explain your business in details: </p>
                          <span>Your OS version, Martex version & build, steps you did. Be VERY precise!</span>
                          <textarea v-model="form.profile_notes" class="form-control message" name="message" rows="6" placeholder="Profile notes..."></textarea>
                        </div>

                        <!-- Contact Form Button -->
                        <div class="col-md-12 mt-15 form-btn text-right">
                          <button @click="send_subscription" class="btn btn--theme hover--theme submit">Update profile</button>
                          <button class="btn btn--tra-black hover--theme" style="margin-left:15px;">Back</button>
                          <button @click="exit" class="btn btn--tra-black hover--theme" style="margin-left:15px;">Disconnect</button>
                        </div>

                        <div class="contact-form-notice">
                          <p class="p-sm">We are committed to your privacy. Martex uses the information you
                            provide us to contact you about our relevant content, products, and services.
                            You may unsubscribe from these communications at any time. For more information,
                            check out our <a href="privacy.html">Privacy Policy</a>.
                          </p>
                        </div>

                        <!-- Contact Form Message -->
                        <div class="col-lg-12 contact-form-msg">
                          <span class="loading"></span>
                        </div>

                      </form>
                    </div>
                  </div>
                </div> <!-- END CONTACT FORM -->
              </div> <!-- END TAB-1 CONTENT -->


              <!-- TAB-2 CONTENT -->
              <div id="tab-2" class="tab-content">
                <div class="accordion-wrapper">
                  <ul class="accordion">

                    <!-- QUESTION #1 -->
                    <li class="accordion-item is-active">

                      <!-- Question -->
                      <div class="accordion-thumb">
                        <h5 class="s-22 w-700">Sign up and manage your account</h5>
                      </div>

                      <!-- Answer -->
                      <div class="accordion-panel color--grey">

                        <!-- Text -->
                        <ol class="digit-list">

                          <li class="list-item">
                            <p>Curabitur ac dapibus libero eu congue tristique neque</p>
                          </li>

                          <li class="list-item">
                            <p>Sagittis congue augue egestas volutpat egestas</p>
                          </li>

                          <li class="list-item">
                            <p>An aliquam justo suscipit congue augue</p>
                          </li>

                          <li class="list-item">
                            <p>Gestas integer congue a lectus porta</p>
                          </li>

                        </ol>

                      </div>

                    </li> <!-- END QUESTION #1 -->


                    <!-- QUESTION #2 -->
                    <li class="accordion-item">

                      <!-- Question -->
                      <div class="accordion-thumb">
                        <h5 class="s-22 w-700">Manage account settings</h5>
                      </div>

                      <!-- Answer -->
                      <div class="accordion-panel color--grey">

                        <!-- Text -->
                        <p>Sapien egestas, congue gestas posuere cubilia congue ipsum mauris lectus
                          laoreet gestas neque and vitae undo auctor dolor luctus placerat a magna
                          cursus congue magna mpedit ligula congue maecenas gravida augue porttitor
                          nunc, quis vehicula magna luctus tempor. Quisque vel laoreet turpis. Viverra
                          augue, a augue tempor, dictum tempor diam. Sed pulvinar a consectetur nibh,
                          imperdiet varius viverra
                        </p>

                      </div>

                    </li> <!-- END QUESTION #2 -->


                    <!-- QUESTION #3 -->
                    <li class="accordion-item">

                      <!-- Question -->
                      <div class="accordion-thumb">
                        <h5 class="s-22 w-700">Change language or location settings</h5>
                      </div>

                      <!-- Answer -->
                      <div class="accordion-panel color--grey">

                        <!-- Text -->
                        <p>Sapien egestas, congue gestas posuere cubilia congue ipsum mauris lectus
                          laoreet gestas neque and vitae undo auctor dolor luctus placerat a magna
                          cursus congue magna mpedit ligula congue maecenas gravida augue porttitor
                          nunc, quis vehicula magna luctus tempor. Quisque vel laoreet turpis. Viverra
                          augue, a augue tempor, dictum tempor diam. Sed pulvinar a consectetur nibh,
                          imperdiet varius viverra
                        </p>

                      </div>

                    </li> <!-- END QUESTION #3 -->


                    <!-- QUESTION #4 -->
                    <li class="accordion-item">

                      <!-- Question -->
                      <div class="accordion-thumb">
                        <h5 class="s-22 w-700">Troubleshoot account issues</h5>
                      </div>

                      <!-- Answer -->
                      <div class="accordion-panel color--grey">

                        <!-- Text -->
                        <p>An augue cubilia laoreet and magna suscipit egestas magna ipsum purus
                          ipsum primis an augue ultrice ligula egestas suscipit lectus gestas
                          integer congue a lectus porta tristique phasellus neque a blandit and
                          tristique justo aliquam justo suscipit congue augue egestas volutpat
                          egestas magna sem congue
                        </p>

                        <!-- Text -->
                        <p>An augue cubilia laoreet and magna suscipit egestas magna ipsum purus
                          ipsum primis and augue efficitur ligula egestas suscipit lectus gestas
                          integer congue a lectus porta phasellus neque
                        </p>

                      </div>

                    </li> <!-- END QUESTION #4 -->


                    <!-- QUESTION #5 -->
                    <li class="accordion-item acc-last-item">

                      <!-- Question -->
                      <div class="accordion-thumb">
                        <h5 class="s-22 w-700">Manage accessibility settings</h5>
                      </div>

                      <!-- Answer -->
                      <div class="accordion-panel color--grey">

                        <!-- Text -->
                        <p>Sapien egestas, congue gestas posuere cubilia congue ipsum mauris
                          lectus laoreet gestas neque and vitae undo auctor dolor luctus placerat
                          a magna cursus congue magna mpedit ligula congue maecenas gravida augue
                          porttitor nunc, quis vehicula magna luctus tempor. Quisque vel laoreet
                          turpis. Viverra augue, a augue tempor, dictum tempor diam. Sed pulvinar
                          a consectetur nibh, imperdiet varius viverra
                        </p>

                        <!-- Text -->
                        <p>Sagittis congue augue egestas volutpat egestas magna suscipit egestas
                          and magna ipsum vitae purus and efficitur ipsum primis in cubilia laoreet
                          tempor gravida
                        </p>

                      </div>

                    </li> <!-- END QUESTION #5 -->

                  </ul>
                </div>
              </div> <!-- END TAB-2 CONTENT -->


              <!-- TAB-3 CONTENT -->
              <div id="tab-3" class="tab-content">
                <div class="accordion-wrapper">
                  <ul class="accordion">

                    <!-- QUESTION #1 -->
                    <li class="accordion-item is-active">

                      <!-- Question -->
                      <div class="accordion-thumb">
                        <h5 class="s-22 w-700">How much does Martex cost?</h5>
                      </div>

                      <!-- Answer -->
                      <div class="accordion-panel color--grey">

                        <!-- Text -->
                        <p>An augue cubilia laoreet and magna suscipit egestas magna ipsum purus
                          ipsum primis undo augue ultrice ligula egestas suscipit lectus gestas
                          integer congue a lectus porta neque blandit tristique
                        </p>

                      </div>

                    </li> <!-- END QUESTION #1 -->


                    <!-- QUESTION #2 -->
                    <li class="accordion-item">

                      <!-- Question -->
                      <div class="accordion-thumb">
                        <h5 class="s-22 w-700">I didn't receive the license key after purchased</h5>
                      </div>

                      <!-- Answer -->
                      <div class="accordion-panel color--grey">

                        <!-- Text -->
                        <ul class="simple-list">

                          <li class="list-item">
                            <p>Curabitur ac dapibus libero. Quisque eu congue tristique neque.
                              Phasellus blandit tristique aliquam justo undo. Aliquam vitae molestie nunc. Quisque sapien justo, aliquet non molestie purus
                              tempor
                            </p>
                          </li>

                          <li class="list-item">
                            <p>Sagittis congue augue egestas volutpat egestas magna suscipit
                              egestas magna ipsum vitae purus undo efficitur ipsum primis in
                              cubilia laoreet augue egestas luctus donec curabitur dapibus libero
                            </p>
                          </li>

                        </ul>

                      </div>

                    </li> <!-- END QUESTION #2 -->


                    <!-- QUESTION #3 -->
                    <li class="accordion-item">

                      <!-- Question -->
                      <div class="accordion-thumb">
                        <h5 class="s-22 w-700">Do you offer discounts for annual plans?</h5>
                      </div>

                      <!-- Answer -->
                      <div class="accordion-panel color--grey">

                        <!-- Text -->
                        <ul class="simple-list">

                          <li class="list-item">
                            <p>Curabitur ac dapibus libero. Quisque eu congue justo undo
                              tristique neque phasellus and blandit tristique aliquam. Aliquam
                              vitae molestie nunc. Quisque sapien justo, aliquet non molestie
                              purus tempor
                            </p>
                          </li>

                          <li class="list-item">
                            <p>Sagittis congue augue egestas volutpat egestas magna suscipit
                              egestas magna ipsum vitae purus undo efficitur ipsum primis in
                              cubilia laoreet augue egestas luctus donec curabitur dapibus libero
                            </p>
                          </li>

                        </ul>

                      </div>

                    </li> <!-- END QUESTION #3 -->


                    <!-- QUESTION #4 -->
                    <li class="accordion-item">

                      <!-- Question -->
                      <div class="accordion-thumb">
                        <h5 class="s-22 w-700">How safe is my data?</h5>
                      </div>

                      <!-- Answer -->
                      <div class="accordion-panel color--grey">

                        <!-- Text -->
                        <p>An augue cubilia laoreet and magna suscipit egestas magna an ipsum
                          purus ipsum primis an augue ultrice ligula egestas suscipit lectus
                          gestas integer congue a lectus porta tristique phasellus neque a blandit
                          undo tristique justo aliquam justo suscipit congue augue egestas volutpat
                          egestas magna sem
                        </p>

                        <!-- Text -->
                        <p>An augue cubilia laoreet and magna suscipit egestas magna ipsum purus
                          ipsum primis and augue efficitur ligula egestas suscipit lectus gestas
                          integer congue a lectus porta phasellus neque
                        </p>

                      </div>

                    </li> <!-- END QUESTION #4 -->


                    <!-- QUESTION #5 -->
                    <li class="accordion-item acc-last-item">

                      <!-- Question -->
                      <div class="accordion-thumb">
                        <h5 class="s-22 w-700">How to request a refund?</h5>
                      </div>

                      <!-- Answer -->
                      <div class="accordion-panel color--grey">

                        <!-- Text -->
                        <ul class="simple-list">

                          <li class="list-item">
                            <p>Curabitur ac dapibus libero. Quisque eu congue tristique</p>
                          </li>

                          <li class="list-item">
                            <p>Congue tristique neque phasellus blandit tristique justo
                              undo aliquam. Aliquam vitae molestie quisque sapien justo,
                              aliquet non molestie purus tempor
                            </p>
                          </li>

                          <li class="list-item">
                            <p>Sagittis congue augue egestas volutpat egestas magna suscipit
                              egestas magna ipsum
                            </p>
                          </li>

                        </ul>

                      </div>

                    </li> <!-- END QUESTION #5 -->

                  </ul>
                </div>
              </div> <!-- END TAB-3 CONTENT -->


              <!-- TAB-4 CONTENT -->
              <div id="tab-4" class="tab-content">
                <div class="accordion-wrapper">
                  <ul class="accordion">


                    <!-- QUESTION #1 -->
                    <li class="accordion-item is-active">

                      <!-- Question -->
                      <div class="accordion-thumb">
                        <h5 class="s-22 w-700">How do I get the error log?</h5>
                      </div>

                      <!-- Answer -->
                      <div class="accordion-panel color--grey">

                        <!-- Text -->
                        <p>Sagittis congue augue egestas volutpat egestas magna suscipit egestas
                          and magna ipsum vitae
                        </p>

                        <!-- Text -->
                        <p>Sagittis congue augue egestas volutpat egestas and magna suscipit an
                          egestas magna ipsum vitae purus congue efficitur ipsum primis in cubilia
                          laoreet augue egestas luctus donec and curabitur dapibus
                        </p>

                      </div>

                    </li> <!-- END QUESTION #1 -->


                    <!-- QUESTION #2 -->
                    <li class="accordion-item">

                      <!-- Question -->
                      <div class="accordion-thumb">
                        <h5 class="s-22 w-700">I forgot my folder password, what should I do?</h5>
                      </div>

                      <!-- Answer -->
                      <div class="accordion-panel color--grey">

                        <!-- Text -->
                        <p>Sagittis congue augue egestas volutpat egestas magna suscipit egestas
                          magna ipsum vitae purus congue efficitur ipsum primis in cubilia laoreet
                          augue egestas luctus donec and curabitur dapibus
                        </p>

                        <!-- Text -->
                        <ol class="digit-list">

                          <li class="list-item">
                            <p>Gestas integer congue a lectus porta</p>
                          </li>

                          <li class="list-item">
                            <p>An aliquam justo suscipit congue augue</p>
                          </li>

                          <li class="list-item">
                            <p>Sagittis congue augue egestas volutpat egestas</p>
                          </li>

                        </ol>

                      </div>

                    </li> <!-- END QUESTION #2 -->


                    <!-- QUESTION #3 -->
                    <li class="accordion-item">

                      <!-- Question -->
                      <div class="accordion-thumb">
                        <h5 class="s-22 w-700">Limitations of folder password protection</h5>
                      </div>

                      <!-- Answer -->
                      <div class="accordion-panel color--grey">

                        <!-- Text -->
                        <p>Sapien egestas, congue gestas posuere cubilia congue ipsum mauris
                          lectus laoreet gestas neque and vitae undo auctor dolor luctus placerat
                          a magna cursus congue magna mpedit ligula congue maecenas gravida augue
                          porttitor nunc, quis vehicula magna luctus tempor. Quisque vel laoreet
                          turpis. Viverra augue, a augue tempor, dictum tempor diam. Sed pulvinar
                          a consectetur nibh, imperdiet varius viverra
                        </p>

                      </div>

                    </li> <!-- END QUESTION #3 -->


                    <!-- QUESTION #4 -->
                    <li class="accordion-item">

                      <!-- Question -->
                      <div class="accordion-thumb">
                        <h5 class="s-22 w-700">Import existing library</h5>
                      </div>

                      <!-- Answer -->
                      <div class="accordion-panel color--grey">

                        <!-- Text -->
                        <p>Sapien egestas, congue gestas posuere cubilia congue ipsum mauris
                          lectus laoreet gestas neque and vitae undo auctor dolor luctus placerat
                          a magna cursus congue magna mpedit ligula congue maecenas gravida augue
                          porttitor nunc, quis vehicula magna luctus tempor. Quisque vel laoreet
                          turpis. Viverra augue, a augue tempor, dictum tempor diam. Sed pulvinar
                          a consectetur nibh, imperdiet varius viverra
                        </p>

                        <!-- Text -->
                        <p>Sagittis congue augue egestas volutpat egestas magna suscipit egestas
                          and magna ipsum vitae purus and efficitur ipsum primis in cubilia laoreet
                          tempor gravida
                        </p>

                      </div>

                    </li> <!-- END QUESTION #4 -->


                    <!-- QUESTION #5 -->
                    <li class="accordion-item acc-last-item">

                      <!-- Question -->
                      <div class="accordion-thumb">
                        <h5 class="s-22 w-700">I lost my license. Can you send it to me?</h5>
                      </div>

                      <!-- Answer -->
                      <div class="accordion-panel color--grey">

                        <!-- Text -->
                        <p>An augue cubilia laoreet and magna suscipit egestas magna ipsum purus
                          ipsum primis undo augue ultrice ligula egestas suscipit lectus gestas
                          integer congue phasellus neque blandit tristique
                        </p>

                        <!-- Text -->
                        <p>Sagittis congue augue egestas volutpat egestas magna suscipit egestas
                          and magna ipsum vitae
                        </p>

                      </div>

                    </li> <!-- END QUESTION #5 -->

                  </ul>
                </div>
              </div> <!-- END TAB-4 CONTENT -->


            </div>
          </div> <!-- End row -->
        </div> <!-- END TABS CONTENT -->


      </section>




    </div> <!-- End container -->
  </section> <!-- END CONTACTS-1 -->

  <!-- BANNER-9
			============================================= -->
  <section id="banner-9" class="bg--02 py-70 x-border banner-section">
    <div class="container">


      <!-- BANNER-9 WRAPPER -->
      <div class="banner-7-wrapper">
        <div class="row justify-content-center d-flex align-items-center">


          <!-- BANNER-9 TEXT -->
          <div class="col-md-7 col-xl-5">
            <div class="banner-9-txt">

              <!-- Title -->
              <h3 class="s-40 w-700">Still need help?</h3>

              <!-- Text -->
              <p class="p-lg">Don't hesitate to contact us about any question you might be interested in</p>

              <!-- Button -->
              <a href="contacts.html" class="btn r-04 btn--theme hover--theme">Ask your question here</a>

            </div>
          </div>


          <!-- BANNER-9 IMAGE -->
          <div class="col-md-5 col-xl-5">
            <div class="banner-9-img text-end">
              <img class="img-fluid" src="images/help.png" alt="banner-image">
            </div>
          </div>


        </div> <!-- End row -->
      </div> <!-- END BANNER-9 WRAPPER -->


    </div> <!-- End container -->
  </section> <!-- END BANNER-9 -->

</template>

<script>
import { RouterLink } from 'vue-router'
import { useRouter } from 'vue-router';
import axios from 'axios';
export default {
  name: 'ProfileEdit',
  component: { RouterLink },
  data() {
    return {
      form: {
        name: '',
        position: '',
        email: '',
        organization: '',
        address: '',
        city: '',
        state: '',
        country: '',
        phone: '',
        mobile: ''
      }
    }
  },
  setup() {
    const router = useRouter();
    return {
      router
    };
  },
  async mounted() {
    const email = sessionStorage.getItem("email");
    await this.member_details(email);
  },
  methods: {
    async member_details(email) {
      const request = await axios({ method: 'GET', url: 'https://unftl-uafatu7bdq-nw.a.run.app/member/details?email=' + email });
      if (request) {
        this.form = request['data'];
      }
    },
    async send_subscription() {
      if (this.form.name == '' || this.form.email == '' || this.form.country == '' || this.form.phone == '' || this.form.site == '') {
        alert("Compilare tutti i campi prima di proseguire");
        return true;
      }
      const request = await axios({
        method: 'POST',
        url: 'https://unftl-uafatu7bdq-nw.a.run.app/member/edit',
        data: this.form
      });
      if (request) {
        if (request['data']['message']) {
          alert(request['data']['message']);
        }
      }
    },
    exit() {
      sessionStorage.clear();
    }
  }
}
</script>