<template>
  <button class="bg-yellow-400 px-4 py-2 rounded-xl font-bold mb-4" @click="router.go(-1)">Torna indietro</button>
  <div class="border mb-6 block border-gray-400 bg-gray-50 p-4 rounded-xl shadow-xl">
    <h4 class="text-lg font-bold mb-2">Verifica richieste</h4>
    <div class="overflow-x-auto">
      <table class="w-full table-auto">
        <thead>
          <tr>
            <th class="border px-4 py-2">Data inserimento richiesta</th>
            <th class="border px-4 py-2">Nome</th>
            <th class="border px-4 py-2">Posizione</th>
            <th class="border px-4 py-2">Organizzazione</th>
            <th class="border px-4 py-2">Stato</th>
          </tr>
        </thead>
        <tbody>
          <tr @click=member_details(member.email) v-for="member, idx in queue" :key="idx" class="hover:bg-gray-200 odd:bg-gray-100 even:bg-gray-50 cursor-pointer">
            <td class="border px-4 py-2">{{ member.request }}</td>
            <td class="border px-4 py-2">{{ member.name }}</td>
            <td class="border px-4 py-2">{{ member.position }}</td>
            <td class="border px-4 py-2">{{ member.organization }}</td>
            <td class="border px-4 py-2 text-red-500">Non approvato</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { useRouter } from 'vue-router';
export default {
  name: 'MemberQueueComponent',
  data() {
    return {
      queue: {}
    }
  },
  setup() {
    const router = useRouter();
    return {
      router
    };
  },
  mounted() {
    this.check_queue();
  },
  methods: {
    async check_queue() {
      const request = await axios({ method: 'GET', url: 'https://unftl-uafatu7bdq-nw.a.run.app/member/queue' });
      if (request) {
        this.queue = request['data'];
      }
    },
    member_details(email){
      this.router.push("/details/"+email);
    }
  }
}
</script>