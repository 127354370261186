<template>
  <HeaderNavigation />

  <section class="ct-01 inner-page-hero content-section division">
    <div class="container">


      <!-- SECTION CONTENT (ROW) -->
      <div class="row d-flex align-items-center">


        <!-- TEXT BLOCK -->
        <div class="col-md-6 order-last order-md-2">
          <div class="txt-block left-column wow fadeInRight">

            <!-- Section ID -->
            <span class="section-id">It all started with a meeting and a dream.</span>

            <!-- Title -->
            <h2 class="s-46 w-700"><strong style="color:#fdf100">2019:</strong> The Birth of an Idea</h2>

            <!-- Text -->
            <p>
              Back in 2019, we kicked things off with just a meeting and a huge
              project. The start of something big in the freight and logistics
              world. We managed to unite 40 different freight forwarders
              globally, for the first time all together under 1 name: TOP40 – a
              group destined to leave its mark.
            </p>

          </div>
        </div> <!-- END TEXT BLOCK -->


        <!-- IMAGE BLOCK -->
        <div class="col-md-6 order-first order-md-2">
          <div class="img-block right-column wow fadeInLeft">
            <img class="img-fluid" src="images/story/2019.jpg" alt="content-image">
          </div>
        </div>


      </div> <!-- END SECTION CONTENT (ROW) -->


    </div> <!-- End container -->
  </section> <!-- END TEXT CONTENT -->

  <!-- BOX CONTENT
			============================================= -->
  <section class="pt-100 ws-wrapper content-section">
    <div class="container">
      <div class="bc-1-wrapper bg--white-400 bg--fixed r-16">
        <div class="section-overlay">
          <div class="row d-flex align-items-center">

            <!-- IMAGE BLOCK -->
            <div class="col-md-6 order-first order-md-2">
              <div class="img-block right-column wow fadeInLeft">
                <img class="img-fluid" src="images/story/2020.png" alt="content-image">
              </div>
            </div>


            <!-- TEXT BLOCK -->
            <div class="col-md-6 order-last order-md-2">
              <div class="txt-block left-column wow fadeInRight">

                <!-- Section ID -->
                <span class="section-id">We found our real identity</span>

                <!-- Title -->
                <h2 class="s-46 w-700"><strong style="color:#fdf100">2020:</strong>: The Transformation</h2>

                <!-- Text -->
                <p>
                  TOP40 became UNITED NATIONS FREIGHT TRADING LOGISTICS
                  The name UN reflects a more inclusive and global approach, indicating that our group represents a wide
                  range of countries and cultures, this helps in showcasing our international presence and diversity.
                  Emphasize the values of unity, cooperation, and shared goals among our members, which are crucial in
                  the logistics and freight industry.
                </p>

              </div>
            </div> <!-- END TEXT BLOCK -->





          </div> <!-- End row -->
        </div> <!-- End section overlay -->
      </div> <!-- End content wrapper -->
    </div> <!-- End container -->
  </section> <!-- END BOX CONTENT -->

  <section class="ct-01 inner-page-hero content-section division">
    <div class="container">


      <!-- SECTION CONTENT (ROW) -->
      <div class="row d-flex align-items-center">

        <!-- IMAGE BLOCK -->
        <div class="col-md-6 order-first order-md-2">
          <div class="img-block right-column wow fadeInLeft">
            <img class="img-fluid" src="images/story/2022.jpg" alt="content-image">
          </div>
        </div>

        <!-- TEXT BLOCK -->
        <div class="col-md-6 order-last order-md-2">
          <div class="txt-block left-column wow fadeInRight">

            <!-- Section ID -->

            <!-- Title -->
            <h2 class="s-46 w-700"><strong style="color:#fdf100">2022:</strong> The Evolution</h2>

            <!-- Text -->
            <p>We officially introduced ourselves to the world as
              UNITED NATIONS FREIGHT TRADING LOGISTICS.
              We came back even stronger, rallying 65 agents
              ready to take a major leap.
              A moment that really stands for unity, growth, and
              our relentless pursuit of excellence.
            </p>

          </div>
        </div> <!-- END TEXT BLOCK -->





      </div> <!-- END SECTION CONTENT (ROW) -->


    </div> <!-- End container -->
  </section> <!-- END TEXT CONTENT -->


  <!-- BOX CONTENT
			============================================= -->
  <section class="pt-100 ws-wrapper content-section">
    <div class="container">
      <div class="bc-1-wrapper bg--white-400 bg--fixed r-16">
        <div class="section-overlay">
          <div class="row d-flex align-items-center">


            <!-- TEXT BLOCK -->
            <div class="col-md-6 order-last order-md-2">
              <div class="txt-block left-column wow fadeInRight">

                <!-- Section ID -->
                <span class="section-id">We smashed expectations and set new standards, despite
                  others trying to copy us</span>

                <!-- Title -->
                <h2 class="s-46 w-700"><strong style="color:#fdf100">2023:</strong> The Confirmation</h2>

                <!-- Text -->
                <p>
                  We went bigger, expanding our space to almost 500sqm
                  with over 90 spots. Our trademark: the massive fair booth,
                  setting the trend for the industry and showing how
                  teamwork and collaboration can make big dreams a
                  reality.
                </p>

              </div>
            </div> <!-- END TEXT BLOCK -->


            <!-- IMAGE BLOCK -->
            <div class="col-md-6 order-first order-md-2">
              <div class="img-block right-column wow fadeInLeft">
                <img class="img-fluid" src="images/story/2023.jpg" alt="content-image">
              </div>
            </div>


          </div> <!-- End row -->
        </div> <!-- End section overlay -->
      </div> <!-- End content wrapper -->
    </div> <!-- End container -->
  </section> <!-- END BOX CONTENT -->


  <section class="ct-01 inner-page-hero content-section division">
    <div class="container">


      <!-- SECTION CONTENT (ROW) -->
      <div class="row d-flex align-items-center">

        <!-- IMAGE BLOCK -->
        <div class="col-md-6 order-first order-md-2">
          <div class="img-block right-column wow fadeInLeft">
            <img class="img-fluid" src="images/story/2024.jpg" alt="content-image">
          </div>
        </div>

        <!-- TEXT BLOCK -->
        <div class="col-md-6 order-last order-md-2">
          <div class="txt-block left-column wow fadeInRight">

            <!-- Section ID -->
            <span class="section-id">The OFFICIAL NETWORK has officially started!</span>

            <!-- Title -->
            <h2 class="s-46 w-700"><strong style="color:#fdf100">2024:</strong>: Beyond Borders</h2>

            <!-- Text -->
            <p>
              With the biggest booth ever, representing the
              endless synergies, collaborations, and
              opportunities in our network.
              It’s not just about how big our stand is,
              but the depth of our connections
              that makes UNFTL a real sector leader.

            </p>

          </div>
        </div> <!-- END TEXT BLOCK -->





      </div> <!-- END SECTION CONTENT (ROW) -->


    </div> <!-- End container -->
  </section> <!-- END TEXT CONTENT -->

  <FooterComponent />


</template>

<script>
import HeaderNavigation from './components/HeaderNavigation.vue';
import FooterComponent from './components/FooterComponent.vue';
export default {
  name: 'OurStory',
  components: { HeaderNavigation, FooterComponent }
}
</script>