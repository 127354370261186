<template>
  <!-- HEADER ============================================= -->
  <header id="header" class="tra-menu navbar-dark light-hero-header white-scroll">
    <div class="header-wrapper">


      <!-- MOBILE HEADER -->
      <div class="wsmobileheader clearfix">
        <span class="smllogo"><img src="images/logo-purple-white.png" alt="mobile-logo"></span>
        <a id="wsnavtoggle" class="wsanimated-arrow"><span></span></a>
      </div>


      <!-- NAVIGATION MENU -->
      <div class="wsmainfull menu clearfix">
        <div class="wsmainwp clearfix">


          <!-- HEADER BLACK LOGO -->
          <div class="desktoplogo">
            <a href="#hero-19" class="logo-black"><img src="images/logo-violet.png" alt="logo"></a>
          </div>


          <!-- HEADER WHITE LOGO -->
          <div class="desktoplogo">
            <a href="/" class="logo-white"><img src="images/logo-violet-white.png" alt="logo"></a>
          </div>


          <!-- MAIN MENU -->
          <nav class="wsmenu clearfix">
            <ul class="wsmenu-list nav-theme">
              <!-- DROPDOWN SUB MENU -->
              <li class="left-back" aria-haspopup="true"><a href="#" class="h-link">About us<span class="wsarrow"></span></a>
                <ul class="sub-menu">
                  <li aria-haspopup="true"><a href="/#/whoweare">Who we are</a></li>
                  <li aria-haspopup="true"><a href="/#/ourstory">Our Story</a></li>
                </ul>
              </li>

              <li class="left-back" aria-haspopup="true"><a href="#" class="h-link">Events<span class="wsarrow"></span></a>
                <ul class="sub-menu">
                  <li aria-haspopup="true"><a href="/#/events">Intermodal South America 2025</a></li>
                  <li aria-haspopup="true"><a href="/#/events">Past events</a></li>
                </ul>
              </li>

              <!-- SIMPLE NAVIGATION LINK -->
              <li class="nl-simple left-back" aria-haspopup="true"><a href="/#/login" class="h-link">Directory</a></li>
              <li class="nl-simple left-back" aria-haspopup="true"><a href="/#/contact" class="h-link">Contact</a></li>

              <li class="nl-simple" aria-haspopup="true" v-if="!auth"><a href="/#/login" class="h-link">
                <img class="login_icon" src="/images/login_ic.png">
                Login</a></li>

              <!-- SIGN UP BUTTON -->
              <li class="nl-simple" aria-haspopup="true" v-if="!auth">
                <a href="/#/member" class="btn r-04 btn--theme hover--theme last-link">Become a member</a>
              </li>

              <li class="nl-simple" aria-haspopup="true" v-if="auth">
                <a href="/#/profile" class="btn r-04 btn--theme hover--theme last-link">Your profile</a>
              </li>

            </ul>
          </nav> <!-- END MAIN MENU -->


        </div>
      </div> <!-- END NAVIGATION MENU -->


    </div> <!-- End header-wrapper -->
  </header> <!-- END HEADER -->
</template>
<script>
export default {
  name: 'HeaderNavigation',
  data() {
    return {
      auth: false
    }
  },
  mounted() {
    const user = sessionStorage.getItem("email");
    const home = sessionStorage.getItem("home");
    if (user && home) {
      this.auth = true;
    } else {
      this.auth = false;
    }
  },
}
</script>