import { createWebHashHistory, createRouter } from 'vue-router'

import Login from './Login.vue'
import IndexView from './IndexView.vue'
import BecomeMember from './BecomeMember.vue'
import MemberQueue from './MemberQueue.vue'
import MemberAll from './MemberAll.vue'
import MemberDetails from './MemberDetails.vue'
import Admin from './AdminView.vue'
import Search from './SearchView.vue'
import ProfileView from './ProfileView.vue'

import BranchAdd from './BranchAdd.vue'
import BranchView from './BranchView.vue'
import BranchQueue from './BranchQueue.vue'
import EventView from './EventView.vue'
import BranchDetails from './BranchDetails.vue'
import ContactView from './ContactView.vue'
import OurStory from './OurStory.vue'
import WhoWeAre from './WhoWeAre.vue'

const routes = [
  { path: '/', component: IndexView },
  { path: '/login', component: Login },
  { path: '/member', component: BecomeMember },
  { path: '/events', component: EventView },
  { path: '/contact', component: ContactView },
  { path: '/ourstory', component: OurStory },
  { path: '/whoweare', component: WhoWeAre },

  { path: '/admin', component: Admin },
  { path: '/search', component: Search },
  { path: '/queue', component: MemberQueue },
  { path: '/all', component: MemberAll },
  { path: '/profile', component: ProfileView },
  { path: '/details/:email', component: MemberDetails },

  
  { path: '/branch/view', component: BranchView },
  { path: '/branch/add', component: BranchAdd },
  { path: '/branch/queue', component: BranchQueue },
  { path: '/branch/edit/:membership_id', component: BranchAdd },
  { path: '/branch/details/:membership_id', component: BranchDetails },
  
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

export default router  