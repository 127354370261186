<template>

  <!-- SIGN UP FORM -->



  <!-- Login Separator -->

  <!-- Form Input -->
  <div class="col-md-12">
    <p class="p-sm input-header">Full name</p>
    <input class="form-control name" autocomplete="name" v-model="form.name" type="text" name="name" placeholder="Your name">
  </div>

  <!-- Form Input -->
  <div class="col-md-12">
    <p class="p-sm input-header">Email address</p>
    <input autocomplete="email" v-model="form.email" class="form-control email" type="email" name="email" placeholder="example@example.com">
  </div>

  <!-- Form Input -->
  <div class="col-md-12">
    <p class="p-sm input-header">Country</p>
    <input autocomplete="country" v-model="form.country" class="form-control email" type="email" name="email" placeholder="Your country" ref="autocompleteInput" @input="handleInput">
  </div>

  <!-- Form Input -->
  <div class="col-md-12">
    <p class="p-sm input-header">Phone Number</p>
    <input autocomplete="tel" v-model="form.phone" class="form-control email" type="email" name="email" placeholder="+ 123 456 789">
  </div>

  <!-- Form Input -->
  <div class="col-md-12">
    <p class="p-sm input-header">Website</p>
    <input autocomplete="site" v-model="form.site" class="form-control email" type="email" name="email" placeholder="yoursite.com">
  </div>

  <!-- Checkbox -->
  <div class="col-md-12">
    <div class="form-data">
      <span>By clicking “Create Account”, you agree to our
        <a href="terms.html">Terms</a> and that you have read our
        <a href="privacy.html"> Privacy Policy</a>
      </span>
    </div>
  </div>

  <!-- Form Submit Button -->
  <div class="col-md-12">
    <button @click="send_subscription" type="submit" class="btn btn--theme hover--theme submit">Create Account</button>
  </div>

</template>

<script>
import { RouterLink } from 'vue-router'
import { useRouter } from 'vue-router';
import axios from 'axios';
import { ref, onMounted } from 'vue';
import { Loader } from '@googlemaps/js-api-loader';
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'
export default {
  name: 'BecomeMemberComponent',
  component: { RouterLink },
  setup() {

    const autocompleteInput = ref(null);
    let autocomplete;

    const handleInput = () => {
      if (autocomplete) {
        console.log('Città selezionata:', autocompleteInput.value.value);
      }
    };

    onMounted(async () => {
      const loader = new Loader({
        apiKey: 'AIzaSyBOMJreHKC8wyxy8UQo8uPLS6tVcXLbiCU',
        version: 'weekly',
        libraries: ['places']
      });

      const google = await loader.load();

      autocomplete = new google.maps.places.Autocomplete(autocompleteInput.value, {
        types: ['country']
      });

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        if (!place.geometry) {
          console.log("Nessun dettaglio disponibile per l'input: '" + place.name + "'");
          return;
        }

        console.log('Città selezionata:', place.name);
        console.log('Latitudine:', place.geometry.location.lat());
        console.log('Longitudine:', place.geometry.location.lng());
        // Qui puoi aggiungere la logica per gestire la città selezionata
      });
    });
    const router = useRouter();
    return {
      autocompleteInput,
      handleInput,
      router
    };
  },
  data() {
    return {
      form: {
        name: '',
        email: '',
        country: '',
        phone: '',
        site: ''
      }
    }
  },
  methods: {
    async send_subscription() {
      if (this.form.name == '' || this.form.email == '' || this.form.country == '' || this.form.phone == '' || this.form.site == '') {
        createToast({
              title: 'Become Member',
              description: "Fill in all the fields of the form before sending the request"
            },
            {
              showIcon: 'true',
              position: 'top-center',
              type: 'danger',
        })
        return true;
      }
      const request = await axios({
        method: 'POST',
        url: 'https://unftl-uafatu7bdq-nw.a.run.app/member/request',
        data: this.form
      });
      if (request) {
        if (request['data']['message']) {
          createToast({
              title: 'Become Member',
              description: request['data']['message']
            },
            {
              showIcon: 'true',
              position: 'top-center',
              type: 'success',
            })
        }
      }
    }
  }
}
</script>