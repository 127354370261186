<template>
    <div class="w-full p-4">
        <MemberDetailsComponent v-if="this.$route.params.email" :email="this.$route.params.email"/>
    </div>
  </template>
  
  <script>
import MemberDetailsComponent from './components/MemberDetailsComponent.vue';
import { useRouter } from 'vue-router';
  export default {
    name: 'BecomeMember',
    components: { MemberDetailsComponent },
    setup() {
    const router = useRouter();
    return {
      router
    };
  }
  }
  </script>
  