<template>
    <button class="bg-yellow-400 px-4 py-2 rounded-xl font-bold mb-4" @click="router.go(-1)">Torna indietro</button>
    <div class="w-full p-4">
        <div class="border mb-6 block border-gray-400 bg-gray-50 p-4 rounded-xl shadow-xl">
            <h4 class="text-lg font-bold mb-2">Coda richieste</h4>
            <div class="overflow-x-auto">
                <table class="w-full table-auto">
                    <thead>
                        <tr>
                            <th class="border px-4 py-2">Branch name</th>
                            <th class="border px-4 py-2">Profile</th>
                            <th class="border px-4 py-2">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr @click=member_details(member?.form?.membership_id) v-for="member, idx in branches" :key="idx" class="hover:bg-gray-200 odd:bg-gray-100 even:bg-gray-50 cursor-pointer">
                            <td class="border px-4 py-2">{{ member?.form?.branchName }}</td>
                            <td class="border px-4 py-2">{{ member.position }}</td>
                            <td class="border px-4 py-2">Non approvato</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <router-link class="bg-orange-500 mb-1 p-2 w-full block" to="/branch/add">Add</router-link>
    </div>
</template>

<script>
import { useRouter } from 'vue-router';
import axios from 'axios';
export default {
    name: 'BranchView',
    data() {
        return {
            branches: []
        }
    },
    setup() {
        const router = useRouter();
        return {
            router
        };
    },
    mounted() {
        this.check_queue();
    },
    methods: {
        async check_queue() {
            const request = await axios({ method: 'GET', url: 'https://unftl-uafatu7bdq-nw.a.run.app/branch/queue' });
            if (request) {
                this.branches = request['data'];
            }
        },
        async member_details(id) {
            this.router.push("/branch/edit/"+id);
        },
    }
}
</script>