<template>

  <!-- LOGIN FORM -->

    <!-- Form Input -->
    <div class="col-md-12">
      <p class="p-sm input-header">Email address</p>
      <input autocomplete="email" v-model="form.email" class="form-control email" type="email" name="email" placeholder="example@example.com">
    </div>

    <!-- Form Input -->
    <div class="col-md-12">
      <p class="p-sm input-header">Password</p>
      <div class="wrap-input">
        <span class="btn-show-pass ico-20"><span class="flaticon-visibility eye-pass"></span></span>
        <input autocomplete="password" v-model="form.password" class="form-control password" type="password" name="password" placeholder="* * * * * * * * *">
      </div>
    </div>

    <!-- Reset Password Link -->
    <div class="col-md-12">
      <div class="reset-password-link">
        <p class="p-sm"><a href="reset-password.html" class="color--theme">Forgot your password?</a></p>
      </div>
    </div>

    <!-- Form Submit Button -->
    <div class="col-md-12">
      <button @click="send" type="submit" class="btn btn--theme hover--theme submit">Log In</button>
    </div>


    <p v-if="response?.message" class="text-center">{{ response.message }}</p>

    

</template>

<script>
import axios from 'axios';
import { useRouter } from 'vue-router';
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'
export default {
  name: 'LogInComponent',
  mounted() {
    const user = sessionStorage.getItem("email");
    const home = sessionStorage.getItem("home");
    if (user && home) {
      console.log(home);
      this.router.push(home);
    }
  },
  setup() {
    const router = useRouter();
    return {
      router
    };
  },
  data() {
    return {
      form: {
        email: '',
        password: ''
      },
      response: {}
    }
  },
  methods: {
    async send() {
      console.log(this.form);
      if (this.form.email == '' && this.form.password == '') {
        createToast({
              title: 'Login',
              description: "Compilare tutti i campi prima di proseguire"
            },
            {
              showIcon: 'true',
              position: 'top-center',
              type: 'danger',
        })
        return true;
      }
      const response = await axios({
        method: 'POST',
        url: 'https://unftl-uafatu7bdq-nw.a.run.app/member/login',
        data: this.form
      });
      if (response) {
        this.response = response['data'];
        if (response['data']['redirect']) {
          this.router.push(response['data']['redirect']);
          sessionStorage.setItem('email', this.form.email);
          sessionStorage.setItem('home', response['data']['redirect']);
        }
      }
    }
  }
}
</script>