<template>

    <HeaderNavigation />

    <section id="single-post" class="pb-90 inner-page-hero blog-page-section">
        <div class="container">
            <div class="row justify-content-center">


                <!-- SINGLE POST CONTENT -->
                <div class="col-xl-10">
                    <div class="post-content">


                        <!--  SINGLE POST TITLE -->
                        <div class="single-post-title text-center">

                            <!-- Post Tag -->
                            <span class="post-tag color--green-400">{{ form.city }}</span>

                            <!-- Title -->
                            <h2 class="s-46 w-700">{{ form.branchName }}</h2>

                            <!-- Post Meta -->
                            <div class="blog-post-meta mt-35">
                                <ul class="post-meta-list ico-10">
                                    <li>
                                        <p class="p-md w-500">{{ form.email }}</p>
                                    </li>
                                    <li class="meta-list-divider">
                                        <p><span class="flaticon-minus"></span></p>
                                    </li>
                                    <li>
                                        <p class="p-md"> {{ form.phone }}</p>
                                    </li>
                                </ul>
                            </div>

                        </div> <!-- END SINGLE POST TITLE -->


                        <!-- SINGLE POST IMAGE -->
                        <div class="blog-post-img py-50">
                            <img class="img-fluid r-16" src="images/blog/post-12-img.jpg" alt="blog-post-image">
                        </div>


                        <!-- SINGLE POST TEXT -->
                        <div class="single-post-txt">

                            <!-- Text -->
                            <p>Sagittis congue augue egestas integer velna purus purus magna nec suscipit and
                                egestas magna aliquam ipsum vitae purus justo lacus ligula ipsum primis cubilia
                                donec undo augue luctus.
                            </p>

                            <!-- PRICING COMPARE -->
                            <div class="comp-table wow fadeInUp">
                                <div class="row">
                                    <div class="col">


                                        <!-- Table -->
                                        <div class="table-responsive mb-50">
                                            <table class="table text-center">

                                                <thead>
                                                    <tr>
                                                        <th style="width: 34%;"></th>
                                                        <th style="width: 22%;"></th>
                                                    </tr>
                                                </thead>

                                                <tbody>

                                                    <tr>
                                                        <th scope="row" class="text-start">Membership ID</th>
                                                        <td class="color--black text-start">{{ form.membership_id }}</td>
                                                    </tr>

                                                    <tr>
                                                        <th scope="row" class="text-start">Status</th>
                                                        <td class="color--black text-start">{{ this.status }}</td>
                                                    </tr>

                                                    <tr>
                                                        <th scope="row" class="text-start">Branch Name</th>
                                                        <td class="color--black text-start">{{ form.branchName }}</td>
                                                    </tr>

                                                    <tr>
                                                        <th scope="row" class="text-start">Address</th>
                                                        <td class="color--black text-start">{{ form.address }}</td>
                                                    </tr>

                                                    <tr>
                                                        <th scope="row" class="text-start">Country</th>
                                                        <td class="color--black text-start">{{ form.country }}</td>
                                                    </tr>

                                                    <tr>
                                                        <th scope="row" class="text-start">City</th>
                                                        <td class="color--black text-start">{{ form.city }}</td>
                                                    </tr>

                                                    <tr>
                                                        <th scope="row" class="text-start">Email</th>
                                                        <td class="color--black text-start">{{ form.email }}</td>
                                                    </tr>

                                                    <tr>
                                                        <th scope="row" class="text-start">Phone</th>
                                                        <td class="color--black text-start">{{ form.phone }}</td>
                                                    </tr>

                                                    <tr>
                                                        <th scope="row" class="text-start">Mobile</th>
                                                        <td class="color--black text-start">{{ form.mobile }}</td>
                                                    </tr>

                                                    <tr>
                                                        <th scope="row" class="text-start">IATA Code</th>
                                                        <td class="color--black text-start">{{ form.iataCode }}</td>
                                                    </tr>

                                                    <tr>
                                                        <th scope="row" class="text-start">Website</th>
                                                        <td class="color--black text-start">{{ form.website }}</td>
                                                    </tr>


                                                </tbody>

                                            </table>
                                        </div> <!-- End Table -->

                                    </div>
                                </div>
                            </div> <!-- END PRICING COMPARE -->


                        </div> <!-- END SINGLE POST TEXT -->

                        <!-- POST COMMENTS -->
                        <div class="post-comments">
                            <div class="comments-wrapper">

                                <!-- COMMENT FORM -->
                                <div id="leave-comment">

                                    <!-- Title -->
                                    <h5 class="s-24 w-700">Contact</h5>

                                    <!-- Text -->
                                    <p>Your email address will not be published. Required fields are marked *</p>

                                    <form name="commentform" class="row comment-form">

                                        <div class="col-md-12 input-message">
                                            <p class="black--color">Add Comment <span>*</span></p>
                                            <textarea class="form-control message" name="message" rows="6" placeholder="Enter Your Comment Here* ..." required></textarea>
                                        </div>

                                        <div class="col-md-12">
                                            <p class="black--color">Name <span>*</span></p>
                                            <input type="text" name="name" class="form-control name" placeholder="Enter Your Name*" required>
                                        </div>

                                        <div class="col-md-12">
                                            <p class="black--color">Email <span>*</span></p>
                                            <input type="email" name="email" class="form-control email" placeholder="Enter Your Email*" required>
                                        </div>

                                        <!-- Contact Form Button -->
                                        <div class="col-lg-12 form-btn">
                                            <button type="submit" class="btn btn--theme hover--theme submit">
                                                Post Comment
                                            </button>
                                        </div>

                                        <!-- Contact Form Message -->
                                        <div class="col-md-12 comment-form-msg text-center">
                                            <div class="sending-msg"><span class="loading"></span></div>
                                        </div>

                                    </form>

                                </div> <!-- END COMMENT FORM -->


                            </div>
                        </div> <!-- END POST COMMENTS -->


                    </div>
                </div> <!-- END  SINGLE POST CONTENT -->


            </div> <!-- End row -->
        </div> <!-- End container -->
    </section> <!-- END SINGLE POST -->

    <button class="bg-yellow-400 px-4 py-2 rounded-xl font-bold mb-4" @click="router.go(-1)">Torna indietro</button>

</template>

<script>
import HeaderNavigation from './components/HeaderNavigation.vue';
import axios from 'axios';
import { useRouter } from 'vue-router';
export default {
    name: 'BranchDetails',
    components: { HeaderNavigation },
    data() {
        return {
            contacts: [
                {
                    name: '',
                    title: '',
                    position: '',
                    phone: '',
                    mobile: '',
                    email: '',
                    skype: '',
                    main: false
                }
            ],
            form: {
                branchName: '',
                address: '',
                country: '',
                city: '',
                email: '',
                phone: '',
                mobile: '',
                iataCode: '',
                website: ''
            },
            membership: {},
            status: "",
            privilege: false
        }
    },
    mounted() {
        if (this.$route.params.membership_id) {
            this.check_queue(this.$route.params.membership_id)
        }
        const home = sessionStorage.getItem("home");
        if (home == '/admin') {
            this.privilege = true;
        }
    },
    setup() {
        const router = useRouter();
        return {
            router
        };
    },
    methods: {
        async check_queue(membership_id) {
            const request = await axios({ method: 'GET', url: 'https://unftl-uafatu7bdq-nw.a.run.app/branch/details?membership_id=' + membership_id });
            if (request) {
                this.form = request['data']['form'];
                this.contacts = request['data']['contact'];
                this.status = request['data']['status']
            }
        },
        addContact() {
            this.contacts.push({
                name: '',
                title: '',
                position: '',
                phone: '',
                mobile: '',
                email: '',
                skype: '',
                main: false
            });
        },
        removeContact(index) {
            this.contacts.splice(index, 1);
        },
        async send_subscription() {
            const request = await axios({
                method: 'POST',
                url: 'https://unftl-uafatu7bdq-nw.a.run.app/branch/add',
                data: {
                    form: this.form,
                    contact: this.contacts,
                    email: sessionStorage.getItem("email")
                }
            });
            if (request) {
                if (request['data']['message']) {
                    alert(request['data']['message']);
                }
            }
        },
        async manage(action) {
            const request = await axios({
                method: 'POST',
                url: 'https://unftl-uafatu7bdq-nw.a.run.app/branch/manage',
                data: {
                    "membership_id": this.$route.params.membership_id,
                    "action": action
                }
            });
            if (request) {
                if (request['data']['message']) {
                    alert(request['data']['message']);
                }
            }
        }
    }
}
</script>